import clsx from 'clsx';
import type { EmbedBlockProps } from '@/types/embeds';
import styles from '@/modules/video-embeds.module.css';
import { ScrollVisible } from '@/components/ScrollVisible';
import { EmbedGDPR } from '@/components/raven/Blocks/embed/EmbedGDPR';
import {
	type FC,
	Children,
	isValidElement,
	type HTMLAttributeReferrerPolicy,
} from 'react';

type IframeProps = {
	allow: string;
	allowFullScreen: boolean;
	height: string;
	loading: 'eager' | 'lazy' | undefined;
	referrerPolicy: HTMLAttributeReferrerPolicy | undefined;
	src: string;
	title: string;
	width: string;
};

export const YouTubeEmbedBlock: FC<EmbedBlockProps> = ({
	block,
	children,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the GDPR and cause a Next.js error
	...rest
}) => {
	const className = clsx(block?.className, styles.videoEmbed, 'fade-in-top');
	let iframe: IframeProps | undefined;

	Children.forEach(children, (child) => {
		if (isValidElement(child)) {
			Children.forEach(child.props.children, (grandchild) => {
				if (isValidElement(grandchild)) {
					if (grandchild.type === 'iframe') {
						const iframeProps = grandchild.props as IframeProps;
						const iframeUrl = new URL(iframeProps.src);
						iframeUrl.searchParams.append('enablejsapi', '1');

						iframe = {
							...iframeProps,
							src: iframeUrl.toString(),
						} as IframeProps;
					}
				}
			});
		}
	});

	if (!iframe) {
		return null;
	}

	return (
		<EmbedGDPR
			avoidReject
			purposes={['content']}
			url={block?.attributes.url}
			vendorId="youtube"
			{...rest}
		>
			<ScrollVisible className={className}>
				<div className="wp-block-embed__wrapper">
					<iframe
						allow={iframe.allow}
						allowFullScreen={iframe.allowFullScreen}
						height={iframe.height}
						loading={iframe.loading}
						referrerPolicy={iframe.referrerPolicy}
						src={iframe.src}
						title={iframe.title}
						width={iframe.width}
					/>
				</div>
			</ScrollVisible>
		</EmbedGDPR>
	);
};
