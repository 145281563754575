import clsx from 'clsx';
import type { FC } from 'react';
import type { Element } from 'html-react-parser';
import { safeJSONParse } from '@/utils/json-parse';
import type { BlockProps } from '@headstartwp/core/react';
import { isInfoblockItemConfig } from '@themaven-net/commerce-shared';
import { CommerceItemContextProvider } from '@/context/CommerceItemContext';
import {
	CommerceInfoblock,
	type CommerceInfoblockProps,
} from '@/components/raven/Commerce/CommerceInfoblock';

export type CommerceInfoblockPropsWithId = {
	id?: number;
} & CommerceInfoblockProps;

export const getInfoblockInfo = (
	domNode: Element,
): CommerceInfoblockPropsWithId => {
	const {
		badge,
		buttonText,
		commerceConfig,
		imageAlt,
		imageAttribution,
		imageUrl,
		skimlinks,
		title,
	} = safeJSONParse(domNode.attribs['data-wp-block']);
	const className = clsx(domNode.attribs.class, {
		noskim: skimlinks,
	});

	if (isInfoblockItemConfig(commerceConfig)) {
		if (title) {
			commerceConfig.title = title;
		}
		if (imageUrl) {
			if (!commerceConfig.options) {
				commerceConfig.options = {};
			}
			commerceConfig.options.imageOverrides = [
				{ alt: imageAlt ?? '', isWebP: false, source: imageUrl },
			];
		}
	}
	return {
		badge,
		buttonTextOverride: buttonText,
		className,
		config: commerceConfig,
		imageAttributionOverride: imageAttribution,
	};
};

export const CommerceInfoblockBlock: FC<BlockProps> = ({ domNode }) => {
	if (!domNode) {
		return null;
	}

	return (
		<CommerceItemContextProvider>
			<CommerceInfoblock {...getInfoblockInfo(domNode)} />
		</CommerceItemContextProvider>
	);
};
