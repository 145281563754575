import React from 'react';
import { GDPR } from '@/components/GDPR';
import { LoaderWrapper } from '@/components/raven/Comments/OpenWeb/LoaderWrapper';

export interface ConversationProps
	extends React.HTMLAttributes<HTMLDivElement> {
	articleTags?: string | string[];
	authorId?: string;
	postId: string;
	postUrl: string;
	scrollToComment?: string;
	scrollToReply?: string;
	socialReviews?: boolean;
	spotId: string;
	theme?: 'dark' | 'light';
}

const ConversationComponent: React.FC<ConversationProps> = ({
	articleTags,
	authorId,
	postId,
	postUrl,
	scrollToComment,
	scrollToReply,
	socialReviews,
	spotId,
	theme,
	...attributes
}) => {
	let tags = null;
	if (articleTags) {
		tags = Array.isArray(articleTags) ? articleTags.join(',') : articleTags;
	}

	return (
		<LoaderWrapper authorId={authorId} spotId={spotId}>
			<div
				{...attributes}
				data-article-tags={tags}
				data-author-id={authorId}
				data-post-id={postId}
				data-post-url={postUrl}
				data-social-reviews={socialReviews}
				data-spot-im-scroll-to-comment={scrollToComment}
				data-spot-im-scroll-to-reply={scrollToReply}
				data-spotim-module="conversation"
				data-theme={theme}
			/>
		</LoaderWrapper>
	);
};

export const Conversation: React.FC<ConversationProps> = (props) => (
	<GDPR purposes={['advertising', 'content']} vendorId="openweb">
		<ConversationComponent {...props} />
	</GDPR>
);
