'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { isSvg } from '@/utils/image-utils';
import type { WPImage } from '@/types/entities';
import { Image } from '@/components/raven/Image';
import Share from '@themaven-net/raven-assets/Share';
import { useScroll } from '@/utils/hooks/use-scroll';
import { decodeEntities } from '@/utils/post-helpers';
import Comment from '@themaven-net/raven-assets/Comment';
import ArrowDown from '@themaven-net/raven-assets/ArrowDown';
import type { Article } from '@/components/ArticleList/types';
import { useCommentsContext } from '@/context/CommentsContext';
import type { ScrollHandlerProps } from '@/types/scroll-handler';
import { useMemo, useState, useEffect, useCallback } from 'react';
import {
	events$,
	type Event,
	ARTICLE_ENTER,
	NAV_STICKY_ENABLE,
	eventsFilterByType,
} from '@/utils/events';

import styles from './styles.module.css';

export const ContextualNavigation = ({
	isSticky = false,
	post,
	settings,
	useLI = false,
}: {
	isSticky: boolean;
	post: Article;
	settings: {
		contextual_nav_logo?: WPImage;
		favicon?: string;
	};
	useLI: boolean;
}) => {
	const { commentsEnabled, openCommentsDrawer } = useCommentsContext();
	const [article, setArticle] = useState<Article | null>(null);
	const [percentageProgress, setPercentageProgress] = useState('0');
	const title = decodeEntities(article?.title?.rendered);

	// Get the logo from the settings if contextual_nav_logo is available otherwise use the favicon
	const logo = settings.contextual_nav_logo || {
			source_url: settings.favicon,
		} || {
			source_url: '',
		};

	const showComments = useMemo(() => {
		return commentsEnabled(post);
	}, [commentsEnabled, post]);

	const openConversationDrawer = useCallback(() => {
		openCommentsDrawer(post);
	}, [openCommentsDrawer, post]);

	// Set the percentage progress on scroll based on the current article
	const updateProgress = useCallback(({ currPos }: ScrollHandlerProps) => {
		const articles = document.querySelectorAll('.article-post');

		const currentArticle = Array.from(articles).find((articleItem) => {
			const articleItemTop = (articleItem as HTMLElement).offsetTop;
			const articleItemBottom =
				articleItemTop + (articleItem as HTMLElement).clientHeight;
			const scroll = currPos;
			return scroll >= articleItemTop && scroll <= articleItemBottom;
		});

		if (!currentArticle) {
			return;
		}

		const articleHeight = currentArticle.clientHeight;
		const articleOffset = (currentArticle as HTMLElement).offsetTop;
		const progress = Math.floor(
			((currPos - articleOffset) * 100) / articleHeight,
		);

		setPercentageProgress(`${progress}`);
	}, []);

	useScroll(updateProgress, []);

	useEffect(() => {
		events$.next({ type: NAV_STICKY_ENABLE, value: !isSticky });
	}, [isSticky]);

	useEffect(() => {
		const subscription = eventsFilterByType(ARTICLE_ENTER).subscribe(
			(event: Event) => setArticle(event.value as Article),
		);
		return () => {
			subscription.unsubscribe();
			events$.next({ type: NAV_STICKY_ENABLE, value: true });
		};
	}, []);

	if (!article || !isSticky || !title) {
		return null;
	}

	const shareData = {
		title,
		url: article.link,
	};

	const showShare =
		!!window.navigator.share && window.navigator.canShare(shareData);

	return (
		<div
			className={clsx(styles.contextualNavigation, {
				[styles.contextualNavigationContinous]: useLI,
			})}
		>
			{logo && logo.source_url && (
				<Link
					aria-label="Go to Homepage"
					className={styles.contextualNavigationLogo}
					href="/"
				>
					<Image
						alt=""
						className={styles.contextualNavigationLogoImg}
						height={20}
						src={logo.source_url}
						unoptimized={isSvg(logo.source_url)}
						width={20}
					/>
				</Link>
			)}
			<span className={styles.contextualNavigationTitle}>
				{useLI && (
					<span className={styles.contextualNavigationUpNext}>
						<ArrowDown />
						Up Next
					</span>
				)}
				{title}
			</span>
			{(showComments || showShare) && (
				<div className={styles.contextualNavigationCtas}>
					{showShare && (
						<button
							aria-label={`Share this article titled: ${title}`}
							className="button-reset"
							onClick={() => window.navigator.share(shareData).catch(() => {})}
							type="button"
						>
							<Share />
						</button>
					)}
					{showComments && (
						<button
							aria-label={`Show comments for this article titled: ${title}`}
							className="button-reset"
							onClick={() => openConversationDrawer()}
							type="button"
						>
							<Comment />
						</button>
					)}
				</div>
			)}
			<div className={styles.contextualNavigationProgress}>
				<div
					className={styles.contextualNavigationProgressInner}
					style={{ transform: `scaleX(${percentageProgress}%)` }}
				/>
			</div>
		</div>
	);
};
