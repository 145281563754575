import type { Post } from '@/types/entities';

export const generatePageClass = (post: Post): string => {
	if (!post) {
		return '';
	}

	/**
	 * Maps content types to their respective CSS class patterns.
	 *
	 * These classes are crucial for the browser extension to function correctly.
	 * The extension relies on the presence of specific class names (`page-id-*`, `post-id-*`,
	 * `author-id-*`, `tag-id-*`, `taxonomy-id-*`) to identify and modify content dynamically.
	 *
	 * Important: Do not modify the structure of these class names.
	 * As long as they remain unchanged, the extension should work as expected.
	 *
	 * Related: [Raven Browser Extension](https://github.com/themaven-net/raven-browser-extension)
	 */
	const typeClassMap: Record<string, string> = {
		author: `taxonomy-archive author-id-${post.id}`,
		'author-post': `taxonomy-archive author-id-${post.id}`,
		'category-post': `taxonomy-archive taxonomy-id-${post.id}`,
		page: `single-page page-id-${post.id}`,
		post: `single-post post-id-${post.id}`,
		'post_tag-post': `taxonomy-archive tag-id-${post.id}`,
	};

	return typeClassMap[post.type] || '';
};
