import clsx from 'clsx';
import type { FC } from 'react';
import type { EmbedBlockProps } from '@/types/embeds';
import styles from '@/modules/video-embeds.module.css';
import { ScrollVisible } from '@/components/ScrollVisible';
import { EmbedGDPR } from '@/components/raven/Blocks/embed/EmbedGDPR';

export const VimeoEmbedBlock: FC<EmbedBlockProps> = ({
	block,
	children,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the GDPR and cause a Next.js error
	...rest
}) => {
	const className = clsx(block?.className, 'fade-in-top', styles.videoEmbed);
	return (
		<EmbedGDPR
			purposes={['content']}
			url={block?.attributes.url}
			vendorId="vimeo"
			{...rest}
		>
			<ScrollVisible className={className}>{children}</ScrollVisible>
		</EmbedGDPR>
	);
};
