'use client';

import type { Post } from '@/types/entities';
import { useOverlayContext } from '@/context/OverlayContext';
import { type FC, useState, useEffect, useCallback } from 'react';
import {
	COMMENTS_VARIANT,
	useCommentsContext,
} from '@/context/CommentsContext';
import { CommentsButton } from '@/components/raven/Comments/CommentsCTA/CommentsButton';

export interface CommentsCTAProps {
	className?: string;
	post: Post;
}

async function fetchCommentsCount(
	spotId: string,
	postId: string,
): Promise<number> {
	let result;
	try {
		result = await fetch(
			`https://open-api.spot.im/v1/messages-count?spot_id=${spotId}&posts_ids=${postId}`,
		);
		if (!result.ok) {
			return 0;
		}
		const json = (await result.json()) as {
			messages_count: { [x: string]: number };
		};
		return json.messages_count[`${postId}`] ?? 0;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(`[OpenWeb] Error fetching the messages-count endpoint`, error);
	}
	return 0;
}

export const CommentsCTA: FC<CommentsCTAProps> = ({ className, post }) => {
	const [commentsCount, setCommentsCount] = useState(0);
	const { openVariant } = useOverlayContext();
	const { commentsEnabled, spotId } = useCommentsContext();
	const openWebCommentsEnabled = commentsEnabled(post);
	const postId = post.meta.tempest_id || '';

	useEffect(() => {
		if (!openWebCommentsEnabled || !spotId) {
			return;
		}
		const fetchData = async () =>
			setCommentsCount(await fetchCommentsCount(spotId, postId));
		fetchData();
	}, [openWebCommentsEnabled, postId, spotId]);

	const openConversationDrawer = useCallback(() => {
		openVariant(COMMENTS_VARIANT, postId, 'comments');
	}, [openVariant, postId]);

	if (!openWebCommentsEnabled || !spotId) {
		return null;
	}

	return (
		<CommentsButton
			className={className}
			commentsCount={commentsCount}
			onClick={() => openConversationDrawer()}
		/>
	);
};
