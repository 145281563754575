import clsx from 'clsx';
import type { FC, ElementType } from 'react';
import type { DOMNode } from 'html-react-parser';
import { isBlockByName } from '@headstartwp/core';
import type { BlockProps } from '@headstartwp/core/react';

export const HeadingBlock: FC<BlockProps> = ({
	block,
	children,
	domNode,
	style,
}) => {
	const tagId = domNode?.attribs?.id || undefined;
	const tagLevel = block?.attributes?.level || 2;
	const TagName = (domNode?.name || `h${tagLevel}`) as ElementType;

	return (
		<TagName className={clsx(block?.className)} id={tagId} style={style}>
			{children}
		</TagName>
	);
};

export const isHeading = (node: DOMNode) => isBlockByName(node, 'core/heading');
