'use client';

import { GDPR } from '@/components/GDPR';
import { isClient } from '@/utils/is-client';
import { useSiteContext } from '@/context/SiteContext';
import { EmbedWrapper } from '@/components/EmbedWrapper';
import { useAdService } from '@/context/AdServiceContext';
import { useGaContext } from '@/context/GoogleAnalyticsContext';
import { serializeExperiments } from '@/utils/serialize-experiments';

import './styles.css';

const handleScriptLoaded = () => {
	if (isClient) {
		window.OBR?.extern.researchWidget();
		// This is just temporary from Dengarden launch. It will be removed once
		// Outbrain do these changes on their side.
		const shadowHosts = document.querySelectorAll('.ob-cards .OUTBRAIN');
		shadowHosts.forEach((host) => {
			if (host.shadowRoot) {
				const elements = host.shadowRoot.querySelectorAll(
					'.ob-grid-layout .ob-rec-image, .ob-grid-layout .ob-rec-rtb-image',
				);
				elements.forEach((el) => {
					if (el instanceof HTMLElement) {
						const element = el;
						element.style.borderRadius = 'var(--radius-media)';
					}
				});
			}
		});
	}
};

export const Outbrain = () => {
	const { sendGaEvent } = useGaContext();
	const { config } = useSiteContext();
	const serializedExperiments = serializeExperiments(
		useAdService().experiments,
	);
	const widgetId =
		config.ad?.contentRecommendations?.providers?.Outbrain?.widgetId ?? 'AR_1';

	if (isClient && window.orion?.handleOutbrainEvent) {
		window.OBREvents = window.OBREvents || [];
		window.OBREvents.push({
			callback(e: OutbrainEvent) {
				sendGaEvent(
					'Outbrain_widget_load',
					{ nonInteraction: true, widgetId: e.widgetId }, // dimensions
				);
				window.orion.handleOutbrainEvent(e);
			},
			feedCards: false,
			name: 'rendered',
			widgetId: 'all',
		});

		window.OBREvents.push({
			callback: window.orion.handleOutbrainEvent,
			feedCards: false,
			name: 'viewability',
			widgetId: 'all',
		});
		window.OBREvents.push({
			callback: window.orion.handleOutbrainEvent,
			feedCards: false,
			name: 'click',
			widgetId: 'all',
		});
	}

	return (
		<GDPR purposes={['content']} vendorId="outbrain">
			<EmbedWrapper
				identifier="outbrain"
				onAlreadyLoaded={handleScriptLoaded}
				onLoad={handleScriptLoaded}
				url=""
			>
				<section className="outbrain-container">
					<div
						className="OUTBRAIN"
						data-external-id={config.siteKeyword}
						data-external-secondary-id={
							serializedExperiments ? `e=${serializedExperiments}` : ''
						}
						data-src={isClient ? window.location.href : ''}
						data-widget-id={widgetId}
					/>
				</section>
			</EmbedWrapper>
		</GDPR>
	);
};
