export const SUPPORTED_EXTERNAL_VIDEOS = {
	vimeo: {
		allow: 'autoplay; fullscreen; picture-in-picture; clipboard-write',
		frameBorder: 0,
		title: 'Vimeo video player',
	},
	youtube: {
		allow:
			'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
		allowFullScreen: true,
		frameBorder: 0,
		referrerPolicy: 'strict-origin-when-cross-origin',
		title: 'YouTube video player',
	},
} as const;
