import type { FC } from 'react';
import { type RavenIcon, getIconComponent } from '@/utils/get-icons';
import { convertMinutesToHours } from '@/utils/recipe/convert-minutes-to-hours';
import styles from '@/components/raven/content/Recipe/RecipeDetails/styles.module.css';

export interface RecipeDetailItemProps {
	icon: RavenIcon;
	itemProp: string;
	label: string;
	needsFormatting?: boolean;
	value?: number | string;
}

export const RecipeDetailItem: FC<RecipeDetailItemProps> = ({
	icon,
	itemProp,
	label,
	needsFormatting = true,
	value,
}) => {
	if (!value) {
		return null;
	}

	const formatedValue = needsFormatting ? convertMinutesToHours(value) : value;

	return (
		<div className={styles.recipeStatGroup}>
			{getIconComponent(icon, 20)}
			<dt className={styles.recipeStatLabel}>{label}</dt>
			<dd className={styles.recipeStatValue} itemProp={itemProp}>
				{formatedValue}
			</dd>
		</div>
	);
};
