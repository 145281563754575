import clsx from 'clsx';
import { type FC, forwardRef } from 'react';
import { Image } from '@/components/raven/Image';
import { VideoPlayer } from '@/components/VideoPlayer';
import { ExternalVideo } from '@/components/ExternalVideo';
import { FallbackVideo } from '@/components/FallbackVideo';
import { Byline } from '@/components/raven/content/Byline';
import { SafeHtml, HtmlDecoder } from '@headstartwp/core/react';
import { decodeAndStripTags } from '@/utils/decode-and-strip-tags';
import { mapCreditsToAuthors } from '@/utils/byline/map-credits-to-authors';
import { ImageSource } from '@/components/raven/Blocks/ImageBlock/ImageSource';
import { filterAuthorsWithoutCredits } from '@/utils/byline/filter-authors-without-credits';
import {
	Breadcrumbs,
	type BreadcrumbItem,
} from '@/components/raven/content/Breadcrumbs';
import type {
	WPImage,
	WPVideo,
	AnalyticsData,
	AuthorTaxonomy,
	ContributorCredits,
} from '@/types/entities';

import styles from './styles.module.css';

interface HeroProps {
	analyticsData: AnalyticsData;
	authors?: AuthorTaxonomy[] | null;
	caption?: string;
	className?: string;
	contributorCredits?: ContributorCredits;
	description?: string;
	image?: null | WPImage;
	items?: BreadcrumbItem[];
	orientation?: 'left' | 'right';
	title: string;
	useFallbackVideo?: boolean;
	video?: null | WPVideo;
}

const IMAGE_SIZES = [
	'(min-width: 1400px) 668px',
	'(min-width: 1024px) calc(50vw - 24px)',
	'calc(100vw - 24px)',
].join(', ');

const MediaPlayer: FC<{
	analyticsData?: AnalyticsData;
	video: WPVideo;
}> = ({ analyticsData, video }) => {
	if (video.type === 'jwplayer') {
		return (
			<VideoPlayer
				analyticsData={analyticsData ?? {}}
				bottomSpacing={false}
				disableAutoplay={video.auto_play_muted}
				videoId={video.platform_id}
				videoImage={video.thumbnail_url}
				videoSrc={video.videoSrc}
				videoTitle={video.video_title}
			/>
		);
	}

	return <ExternalVideo url={video.embed_url} />;
};

export const Hero = forwardRef<HTMLElement, HeroProps>(function Hero(
	{
		analyticsData,
		authors,
		caption,
		className,
		contributorCredits,
		description,
		image,
		items,
		orientation = 'right',
		title,
		useFallbackVideo = false,
		video,
	},
	ref,
) {
	// Prefer video for featured media
	const hasVideo = video && video.embed_url;
	// Secondary choice is a featured image
	const hasImage = image && !hasVideo;
	// If useFallbackVideo is enabled, and no featured video or image
	const hasFallbackVideo = useFallbackVideo && !hasImage && !hasVideo;

	const hasNoMedia = !hasImage && !hasVideo && !hasFallbackVideo;

	const safeCaption = decodeAndStripTags(caption);

	const hasSource = image?.meta?.source_name || image?.meta?.source_url;
	const hasAuthors = authors && authors.length > 0;

	const creditsMap = mapCreditsToAuthors(contributorCredits, authors);
	const filteredAuthors = filterAuthorsWithoutCredits(
		authors,
		contributorCredits,
	);

	return (
		<section className={clsx(styles.heroWrapper, className)} ref={ref}>
			<div className={styles.heroContainer}>
				<Breadcrumbs items={items} listClassName={styles.heroBreadcrumbs} />

				<div
					className={clsx(styles.heroContent, {
						[styles.heroContentLeft]: orientation === 'left',
						[styles.heroContentWithoutMedia]: hasNoMedia,
					})}
				>
					<div className={styles.heroInfo}>
						<div className={styles.heroInfoHead}>
							<h1 className={styles.heroTitle}>
								<SafeHtml html={title} />
							</h1>

							{description && (
								<p className={styles.heroDescription}>
									<HtmlDecoder html={description} />
								</p>
							)}
						</div>

						{hasAuthors && (
							<div className={styles.heroArticleByline}>
								<Byline contributors={filteredAuthors} credits={creditsMap} />
							</div>
						)}
					</div>

					{hasVideo && (
						<MediaPlayer analyticsData={analyticsData} video={video} />
					)}

					{hasFallbackVideo && <FallbackVideo />}

					{hasImage && (
						<figure className={styles.heroImageWrapper}>
							<Image
								alt={safeCaption}
								aspectRatio="4:3"
								className={styles.heroImage}
								focalX={image?.meta?.focal_point?.x}
								focalY={image?.meta?.focal_point?.y}
								height={image.media_details?.height}
								priority
								sizes={IMAGE_SIZES}
								src={`${image && image.source_url ? image.source_url : ''}`}
								width={image.media_details?.width}
							/>

							{(caption || hasSource) && (
								<figcaption className={styles.heroImageCaption}>
									{caption && (
										<span className={styles.heroImageCaptionText}>
											<SafeHtml html={caption} />
										</span>
									)}

									{hasSource && (
										<ImageSource
											className={styles.heroImageSource}
											sourceName={image.meta?.source_name || ''}
											sourceUrl={image.meta?.source_url}
										/>
									)}
								</figcaption>
							)}
						</figure>
					)}
				</div>
			</div>
		</section>
	);
});
