/* eslint-disable react/no-danger */
import type { FC } from 'react';
import type { SupportedSchema } from '@/types/entities';

type SchemaProps = {
	schema?: SupportedSchema | SupportedSchema[];
};

export const Schema: FC<SchemaProps> = ({ schema }) => {
	if (!schema) {
		return null;
	}

	return (
		<script
			dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
			type="application/ld+json"
		/>
	);
};
