import type { FC } from 'react';
import { Numbrix } from '@/components/raven/Numbrix';
import type { BlockProps } from '@headstartwp/core/react';

export const NumbrixBlock: FC<BlockProps> = ({
	block,
	blockContext,
	domNode,
}) => {
	if (!domNode || !domNode.attribs || !block) {
		return null;
	}

	const { data } = block.attributes;
	const date = (blockContext?.date as string) || '';
	const flavor = (blockContext?.flavor as string) || '';
	const difficulty = (blockContext?.difficulty as string) || '';

	return (
		<Numbrix
			data={data as string}
			date={date}
			flavor={flavor}
			level={difficulty}
		/>
	);
};
