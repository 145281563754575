import { useBreakpoint } from '@/utils/hooks/use-breakpoint';
import {
	HEADER_HEIGHT_PX,
	HEADER_HEIGHT_MOBILE,
	HEADER_HEIGHT_TABLET,
} from '@/constants';

export function useHeaderHeight() {
	const breakpoint = useBreakpoint();
	let headerHeight = HEADER_HEIGHT_MOBILE;

	if (breakpoint === 'B') {
		headerHeight = HEADER_HEIGHT_TABLET;
	} else if (breakpoint !== 'A') {
		headerHeight = HEADER_HEIGHT_PX;
	}

	return headerHeight;
}
