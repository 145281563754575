import type { FC } from 'react';
import { AdSlot } from '@/components/AdSlot';
import type { Element } from 'html-react-parser';
import { safeJSONParse } from '@/utils/json-parse';
import type { BlockProps } from '@headstartwp/core/react';

/**
 * InContentAdBlock React component.
 * This is a server-side component which returns markup for the client-side AdSlot
 * React component. Expected attributes on the provided `domNode`:
 * - data-smart-slot-config: The configuration for the ad slot (identifies breakpoint, zone, and index).
 * - id: The unique DOM id to apply for the ad slot.
 * @param {Object} props - The props object, which includes a domNode.
 */
export const InContentAdBlock: FC<BlockProps> = ({ domNode }) => {
	if (!domNode || !domNode.attribs) {
		return null;
	}
	const { 'data-smart-slot-config': slotConfig, id: slotId } = domNode.attribs;
	if (!slotConfig) {
		return null;
	}
	const data = safeJSONParse(slotConfig);
	const { breakpoint, index, zone } = data;
	return (
		<AdSlot
			breakpoint={breakpoint}
			id={slotId}
			index={index}
			reserveHeight
			zone={zone}
		/>
	);
};

export const isAdSmartSlot = (node: Element) => {
	if (node.name === 'div' && node.attribs?.['data-smart-slot'] === 'ad') {
		// Assign these to the node attributes to suppress 10up warning that this isn't
		// a recognized block.
		// eslint-disable-next-line no-param-reassign
		node.attribs['data-wp-block-name'] = 'arena/ad';
		// eslint-disable-next-line no-param-reassign
		node.attribs['data-wp-block'] = '';
		return true;
	}
	return false;
};
