import clsx from 'clsx';
import dayjs from 'dayjs';
import type { FC } from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import styles from './styles.module.css';

interface ArticleTimestampProps {
	className?: string;
	date: string;
	modified: string;
	timeZone: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const getTimezoneAbbreviation = (
	date: dayjs.Dayjs,
	timeZone: string,
): string => {
	return (
		new Intl.DateTimeFormat('en-US', {
			timeZone,
			timeZoneName: 'short',
		})
			.formatToParts(date.toDate())
			.find((part) => part.type === 'timeZoneName')?.value || ''
	);
};

const formatDate = (date: dayjs.Dayjs, timeZone: string): string => {
	const formattedDate = date.format('MMM D, YYYY h:mm A');
	const timezoneAbbreviation = getTimezoneAbbreviation(date, timeZone);
	return `${formattedDate} ${timezoneAbbreviation}`;
};

export const ArticleTimestamp: FC<ArticleTimestampProps> = ({
	className = '',
	date,
	modified,
	timeZone,
}) => {
	const createdDate = dayjs(date).tz(timeZone, true);
	const modifiedDate = dayjs(modified).tz(timeZone, true);

	const isMinimalUpdate = modifiedDate.diff(createdDate, 'seconds') < 60;

	const updatedDate = isMinimalUpdate
		? createdDate.toISOString()
		: modifiedDate.toISOString();

	const displayDate = isMinimalUpdate
		? formatDate(createdDate, timeZone)
		: `UPDATED: ${formatDate(modifiedDate, timeZone)}`;

	return (
		<time
			className={clsx(styles.articleTimestamp, className)}
			dateTime={updatedDate}
		>
			{displayDate}
		</time>
	);
};
