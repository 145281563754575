import type { FC } from 'react';
import type { Post } from '@/types/entities';
import { SmartSlot } from '@/components/raven/SmartSlot';
import type { BlockProps } from '@headstartwp/core/react';

type SmartSlotBlockProps = {
	item: Partial<Post>;
} & BlockProps;

type SmartSlotBlockAttributes = {
	variant: string;
};

export const SmartSlotBlock: FC<SmartSlotBlockProps> = ({ block, item }) => {
	if (!block) {
		return null;
	}

	const { variant } = block.attributes as unknown as SmartSlotBlockAttributes;

	return <SmartSlot item={item} variant={variant} />;
};
