'use client';

import clsx from 'clsx';
import { type FC, useMemo, useCallback } from 'react';
import SvgComment from '@themaven-net/raven-assets/Comment';
import { Button, type ClickButtonProps } from '@/components/raven/ui/Button';
import styles from '@/components/raven/Comments/CommentsCTA/styles.module.css';

export type CommentsButtonProps = {
	commentsCount: number;
} & ClickButtonProps;

const formatCommentsCount = (n: number): string => {
	// When there are no comments nothing is shown
	if (n <= 0) {
		return '';
	}
	let commentsCountText = '';
	// When there are less than 100 comments it'll show the number
	if (n < 100) {
		commentsCountText = `${n}`;
	}
	// When there are more than 99 and less than 1000 it shows 99+
	if (n > 99 && n < 1000) {
		commentsCountText = '99+';
	}
	/**
	 * Otherwise it'll present the number of comments formatted like this:
	 * n >= 1000 and n < 1100 => 1K
	 * n >= 1100 and n < 1200 => 1.1K
	 * n >= 1200 and n < 1300 => 1.2K
	 * ... and so on
	 */
	if (n > 999) {
		const i = parseInt((n / 1000).toString(), 10);
		const decimals = parseInt(((n - i * 1000) / 100).toString(), 10);
		commentsCountText = `${i}${decimals === 0 ? '' : `.${decimals}`}K`;
	}
	return `(${commentsCountText})`;
};

export const CommentsButton: FC<CommentsButtonProps> = ({
	children,
	className,
	commentsCount,
	onClick,
	variation = 'primary',
	...buttonProps
}) => {
	const cx = clsx(className, styles.commentsButton);
	const commentsCountText = useMemo(
		() => formatCommentsCount(commentsCount),
		[commentsCount],
	);
	const buttonText = `${commentsCount > 0 ? 'Join' : 'Start'} the Conversation ${commentsCountText}`;

	const onClickHandler = useCallback(() => {
		if (onClick) {
			onClick();
		}
	}, [onClick]);

	return (
		<Button
			className={cx}
			onClick={onClickHandler}
			variation={variation}
			{...buttonProps}
		>
			{buttonText}
			<SvgComment {...{ height: 16, width: 16 }} />
		</Button>
	);
};
