import type { FC } from 'react';
import { GDPR, OnReject, type GDPRProps } from '@/components/GDPR';
import { EmbedFail } from '@/components/raven/Blocks/embed/EmbedFail';

type EmbedGDPRProps = {
	avoidReject?: boolean;
	identifier?: string | unknown;
	url?: string | unknown;
} & GDPRProps;

export const EmbedGDPR: FC<EmbedGDPRProps> = ({
	avoidReject,
	children,
	identifier,
	url,
	...rest
}) => (
	<GDPR {...rest}>
		{children}

		{!avoidReject && (
			<OnReject>
				<EmbedFail
					identifier={(identifier || rest.vendorId || '') as string}
					url={(url || '') as string}
				/>
			</OnReject>
		)}
	</GDPR>
);
