import type { FC } from 'react';
import { allowedList } from '@/constants';
import { BlocksRenderer } from '@headstartwp/core/react';
import { PollBlock } from '@/components/raven/Blocks/PollBlock';
import { PostsBlock } from '@/components/raven/Blocks/PostsBlock';
import { ImageBlock } from '@/components/raven/Blocks/ImageBlock';
import { QuoteBlock } from '@/components/raven/Blocks/QuoteBlock';
import { VideoBlock } from '@/components/raven/Blocks/VideoBlock';
import { ButtonBlock } from '@/components/raven/Blocks/ButtonBlock';
import { RecipeBlock } from '@/components/raven/Blocks/RecipeBlock';
import { GalleryBlock } from '@/components/raven/Blocks/GalleryBlock';
import { NumbrixBlock } from '@/components/raven/Blocks/NumbrixBlock';
import type { AnalyticsData, RecipePostMeta } from '@/types/entities';
import { PullQuoteBlock } from '@/components/raven/Blocks/PullQuoteBlock';
import { SmartSlotBlock } from '@/components/raven/Blocks/SmartSlotBlock';
import { PageHeaderBlock } from '@/components/raven/Blocks/PageHeaderBlock';
import type { BreadcrumbItem } from '@/components/raven/content/Breadcrumbs';
import { MediaCarouselBlock } from '@/components/raven/Blocks/MediaCarouselBlock';
import { VimeoEmbedBlock } from '@/components/raven/Blocks/embed/VimeoEmbedBlock';
import {
	isHeading,
	HeadingBlock,
} from '@/components/raven/Blocks/HeadingBlock';
import { RedditEmbedBlock } from '@/components/raven/Blocks/embed/RedditEmbedBlock';
import { TikTokEmbedBlock } from '@/components/raven/Blocks/embed/TikTokEmbedBlock';
import { TwitterEmbedBlock } from '@/components/raven/Blocks/embed/TwitterEmbedBlock';
import { YouTubeEmbedBlock } from '@/components/raven/Blocks/embed/YouTubeEmbedBlock';
import { TableOfContentsBlock } from '@/components/raven/Blocks/TableOfContentsBlock';
import { JotformEmbedBlock } from '@/components/raven/Blocks/embed/JotformEmbedBlock';
import { ArticleCard5050Block } from '@/components/raven/Blocks/ArticleCard5050Block';
import { CommerceCtaBlock } from '@/components/raven/Blocks/commerce/CommerceCtaBlock';
import { FacebookEmbedBlock } from '@/components/raven/Blocks/embed/FacebookEmbedBlock';
import { CarouselCategoryBlock } from '@/components/raven/Blocks/CarouselCategoryBlock';
import { CommerceLinkBlock } from '@/components/raven/Blocks/commerce/CommerceLinkBlock';
import { InstagramEmbedBlock } from '@/components/raven/Blocks/embed/InstagramEmbedBlock';
import { PollDaddyEmbedBlock } from '@/components/raven/Blocks/embed/PollDaddyEmbedBlock';
import {
	isNewsletter,
	NewsletterBlock,
} from '@/components/raven/Blocks/NewsletterBlock';
import {
	isAdSmartSlot,
	InContentAdBlock,
} from '@/components/raven/Blocks/InContentAdBlock';
import {
	isFallbackVideo,
	FallbackVideoBlock,
} from '@/components/Blocks/FallbackVideoBlock';
import {
	HtmlScriptBlock,
	isHtmlScriptBlock,
} from '@/components/raven/Blocks/HtmlScriptBlock';
import { CommerceInfoblockBlock } from '@/components/raven/Blocks/commerce/CommerceInfoblockBlock';

interface BlocksProps {
	analyticsData?: AnalyticsData;
	blockContext?: Record<string, unknown>;
	breadcrumbs?: BreadcrumbItem[];
	content: string;
	recipeDetails?: RecipePostMeta;
}

export const Blocks: FC<BlocksProps> = ({
	analyticsData,
	blockContext,
	breadcrumbs,
	content,
	recipeDetails,
}) => {
	return (
		<BlocksRenderer
			blockContext={blockContext}
			forwardBlockAttributes
			html={content ?? ''}
			ksesAllowList={allowedList}
		>
			<ArticleCard5050Block
				classList="wp-block-the-arena-group-article-card-5050"
				tagName="section"
			/>

			<ButtonBlock classList="wp-block-the-arena-group-button" tagName="div" />

			<HeadingBlock test={isHeading} />

			<HtmlScriptBlock test={isHtmlScriptBlock} />

			<PullQuoteBlock classList="wp-block-pullquote" tagName="figure" />

			<PageHeaderBlock
				breadcrumbs={breadcrumbs}
				classList="wp-block-the-arena-group-page-header"
				tagName="section"
			/>

			<QuoteBlock
				classList="wp-block-the-arena-group-quote-with-image"
				tagName="section"
			/>

			<TableOfContentsBlock
				classList="wp-block-the-arena-group-toc"
				tagName="section"
			/>
			<PollBlock classList="wp-block-the-arena-group-poll" tagName="section" />
			<PollDaddyEmbedBlock
				classList="wp-block-embed-crowdsignal"
				height={380}
				lazy
				tagName="figure"
			/>

			<JotformEmbedBlock
				classList="wp-block-embed-jotform"
				height={380}
				lazy
				tagName="figure"
			/>

			<ImageBlock classList="wp-block-image" tagName="figure" />

			<PostsBlock
				classList="wp-block-the-arena-group-posts"
				tagName="section"
			/>

			<FacebookEmbedBlock
				aspectRatio={9 / 16}
				classList="wp-block-embed-facebook"
				lazy
				tagName="figure"
			/>

			<GalleryBlock classList="wp-block-gallery" tagName="figure" />

			<InContentAdBlock
				data-smart-slot
				data-smart-slot-config
				test={isAdSmartSlot}
			/>

			<InstagramEmbedBlock
				classList="wp-block-embed-instagram"
				height={739}
				lazy
				tagName="figure"
			/>

			<MediaCarouselBlock
				classList="wp-block-the-arena-group-collection-carousel"
				tagName="section"
			/>

			<RedditEmbedBlock
				classList="wp-block-embed-reddit"
				height={740}
				lazy
				tagName="figure"
			/>

			<TikTokEmbedBlock
				classList="wp-block-embed-tiktok"
				height={739}
				lazy
				tagName="figure"
			/>

			<TwitterEmbedBlock
				aspectRatio={9 / 16}
				classList="wp-block-embed-twitter"
				lazy
				tagName="figure"
			/>

			<YouTubeEmbedBlock
				aspectRatio={16 / 9}
				classList="wp-block-embed-youtube"
				lazy
				tagName="figure"
			/>

			<VimeoEmbedBlock
				aspectRatio={16 / 9}
				classList="wp-block-embed-vimeo"
				lazy
				tagName="figure"
			/>

			<VideoBlock
				analyticsData={analyticsData ?? {}}
				aspectRatio={16 / 9}
				classList="wp-block-jw-player-video"
				lazy
				tagName="section"
			/>

			<FallbackVideoBlock
				data-smart-slot
				data-smart-slot-config
				test={isFallbackVideo}
			/>

			<NewsletterBlock
				data-smart-slot
				data-smart-slot-config
				test={isNewsletter}
			/>

			<NumbrixBlock
				classList="wp-block-the-arena-group-numbrix"
				tagName="div"
			/>

			<RecipeBlock
				classList="wp-block-the-arena-group-recipe"
				recipeDetails={recipeDetails}
				tagName="section"
			/>

			<CommerceCtaBlock
				classList="wp-block-the-arena-group-commerce-button"
				tagName="section"
			/>

			<CommerceInfoblockBlock
				classList="wp-block-the-arena-group-commerce-info"
				tagName="section"
			/>

			<CarouselCategoryBlock
				classList="wp-block-the-arena-group-carousel-category"
				tagName="section"
			/>

			<CommerceLinkBlock classList="raven-commerce-link" tagName="a" />

			<SmartSlotBlock
				classList="wp-block-the-arena-group-smart-slot"
				item={analyticsData?.post ?? {}} // analyticsData?.post contains a copy of the current item / post
				tagName="section"
			/>
		</BlocksRenderer>
	);
};
