'use client';

import { clsx } from 'clsx';
import { isSvg } from '@/utils/image-utils';
import { Image } from '@/components/raven/Image';
import { Button } from '@/components/raven/ui/Button';
import { OAuthProviderType } from '@/context/LoginContext';
import { Checkbox } from '@/components/raven/Inputs/Checkbox';
import { useAppSettings } from '@/context/AppSettingsContext';
import ArrowRight from '@themaven-net/raven-assets/ArrowRight';
import { TextField } from '@/components/raven/Inputs/TextField';
import type { AppSettings } from '@/utils/data/fetch-app-settings';
import {
	useId,
	type FC,
	useState,
	type FormEvent,
	type ChangeEvent,
} from 'react';
import {
	AppleLogo,
	GoogleColored,
	FacebookColored,
	MicrosoftColored,
} from '@themaven-net/raven-assets';

import styles from './styles.module.css';

export type LoginFormStatus = 'error' | 'idle' | 'loading' | 'success';
export interface LoginFormProps {
	buttonText: string;
	checkboxText: JSX.Element | string;
	className?: string;
	descriptionDefault?: string;
	descriptionError?: string;
	descriptionLoading?: string;
	descriptionSuccess?: string;
	handleOAuthSignIn?: (social: OAuthProviderType) => void;
	onContinue?: (email: string) => void;
	onEmailChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	settings: AppSettings['settings'];
	status?: LoginFormStatus;
	textFieldPlaceholder: string;
	titleDefault: string;
	titleError?: string;
	titleLoading?: string;
	titleSuccess?: string;
}

export type LoginButtonProps = {
	onClick: () => void;
};

export const GoogleLoginButton: FC<LoginButtonProps> = ({ onClick }) => (
	<Button onClick={onClick} variation="secondary">
		Continue with Google <GoogleColored width="16" />
	</Button>
);

export const FacebookLoginButton: FC<LoginButtonProps> = ({ onClick }) => (
	<Button onClick={onClick} variation="secondary">
		Continue with Facebook <FacebookColored width="16" />
	</Button>
);

export const AppleLoginButton: FC<LoginButtonProps> = ({ onClick }) => (
	<Button onClick={onClick} variation="secondary">
		Continue with Apple <AppleLogo width="16" />
	</Button>
);

export const MicrosoftLoginButton: FC<LoginButtonProps> = ({ onClick }) => (
	<Button onClick={onClick} variation="secondary">
		Continue with Microsoft <MicrosoftColored width="16" />
	</Button>
);

interface LoginFormHeaderProps {
	description?: string;
	logo?: {
		alt_text: string;
		media_details: {
			height: number;
			width: number;
		};
		source_url: string;
	};
	title?: string;
}

export const LoginFormHeader: FC<LoginFormHeaderProps> = ({
	description,
	logo,
	title,
}) => {
	return (
		<div className={styles.loginFormHeader}>
			{logo && (
				<div className={styles.loginFormLogo}>
					<Image
						alt={logo.alt_text}
						height={logo.media_details.height}
						sizes="40px"
						src={logo.source_url}
						unoptimized={isSvg(logo.source_url)}
						width={logo.media_details.width}
					/>
				</div>
			)}
			<h2 className={styles.loginFormTitle}>{title}</h2>
			{description && (
				<p className={styles.loginFormDescription}>{description}</p>
			)}
		</div>
	);
};

export const LoginForm: FC<LoginFormProps> = ({
	buttonText,
	checkboxText,
	className,
	descriptionDefault,
	descriptionError,
	descriptionLoading,
	descriptionSuccess,
	handleOAuthSignIn,
	onContinue,
	onEmailChange,
	settings,
	status = 'idle',
	textFieldPlaceholder,
	titleDefault,
	titleError,
	titleLoading,
	titleSuccess,
}) => {
	const logo = settings?.header_logo;
	const checkboxId = useId();
	const termsErrorMessage = 'You must agree the terms to proceed';
	const [termsAccepted, setTermsAccepted] = useState(false);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.currentTarget.checkValidity()) {
			const formData = new FormData(e.currentTarget);
			const email = formData.get('email');
			onContinue?.(email?.toString() || '');
		}
	};

	const getStatusContent = () => {
		const title = {
			error: titleError,
			idle: titleDefault,
			loading: titleLoading,
			success: titleSuccess,
		}[status];

		const description = {
			error: descriptionError,
			idle: descriptionDefault,
			loading: descriptionLoading,
			success: descriptionSuccess,
		}[status];

		return (
			<LoginFormHeader description={description} logo={logo} title={title} />
		);
	};

	const getFormContent = () => {
		if (status !== 'idle') return null;

		return (
			<>
				<form className={styles.loginFormBody} onSubmit={handleSubmit}>
					<TextField
						className={styles.loginFormInput}
						label="Email"
						labelHidden
						name="email"
						onChange={onEmailChange}
						placeholder={textFieldPlaceholder}
						required
						type="email"
					/>
					<Button
						className={styles.loginFormButton}
						onClick={() => {}}
						type="submit"
						variation="primary"
					>
						{buttonText}
					</Button>
					<Checkbox
						checked={termsAccepted}
						className={styles.loginFormCheckbox}
						id={`terms-agreement-${checkboxId}`}
						label={checkboxText}
						name="terms-agreement"
						onChange={() => {
							setTermsAccepted((ta) => !ta);
						}}
						required
						requiredErrorMessage={termsErrorMessage}
					/>
				</form>
				<p className={styles.loginFormSeparator}>
					<span>or</span>
				</p>
				<div className={styles.loginFormSocialMethods}>
					<GoogleLoginButton
						onClick={() => handleOAuthSignIn?.(OAuthProviderType.GOOGLE)}
					/>
					<FacebookLoginButton
						onClick={() => handleOAuthSignIn?.(OAuthProviderType.FACEBOOK)}
					/>
					<AppleLoginButton
						onClick={() => handleOAuthSignIn?.(OAuthProviderType.APPLE)}
					/>
					<MicrosoftLoginButton
						onClick={() => handleOAuthSignIn?.(OAuthProviderType.MICROSOFT)}
					/>
				</div>
			</>
		);
	};

	return (
		<div className={clsx(styles.loginForm, className)}>
			{getStatusContent()}
			{getFormContent()}
		</div>
	);
};

export interface CreateUsernameProps {
	description?: string;
	errorMessage?: string;
	onContinue?: (email: string) => void;
	onUsernameChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CreateUsername: FC<CreateUsernameProps> = ({
	description,
	errorMessage,
	onContinue,
	onUsernameChange,
}) => {
	const { settings } = useAppSettings();
	const logo = settings?.header_logo;
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.currentTarget.checkValidity()) {
			const formData = new FormData(e.currentTarget);
			const email = formData.get('email');
			onContinue?.(email?.toString() || '');
		}
	};

	return (
		<>
			<LoginFormHeader
				description={errorMessage || description || 'Create a username'}
				logo={logo}
				title="Complete Your Profile"
			/>
			<form className={styles.loginFormBody} onSubmit={handleSubmit}>
				<TextField
					className={styles.loginFormInput}
					label="User Name"
					labelHidden
					name="username"
					onChange={onUsernameChange}
					placeholder="User Name"
					required
					type="text"
				/>
				<Button
					className={styles.loginFormButton}
					onClick={() => {}}
					type="submit"
					variation="primary"
				>
					Save and Continue <ArrowRight />
				</Button>
			</form>
		</>
	);
};
