import type { FC } from 'react';
import type { BlockProps } from '@headstartwp/core/react';
import {
	PageHeader,
	type PageHeaderProps,
} from '@/components/raven/content/PageHeader';

type PageHeaderBlockProps = BlockProps & Pick<PageHeaderProps, 'breadcrumbs'>;

type PageHeaderBlockAttributes = Omit<
	PageHeaderProps,
	'breadcrumbs' | 'className'
>;

export const PageHeaderBlock: FC<PageHeaderBlockProps> = ({
	block,
	breadcrumbs,
}) => {
	if (!block) {
		return null;
	}

	const {
		backgroundImage,
		brandImage,
		cta,
		description,
		showBreadcrumbs,
		title,
	} = block.attributes as unknown as PageHeaderBlockAttributes;

	if (!title) {
		return null;
	}

	return (
		<PageHeader
			backgroundImage={backgroundImage}
			brandImage={brandImage}
			breadcrumbs={breadcrumbs}
			className={block.className}
			cta={cta}
			description={description}
			showBreadcrumbs={showBreadcrumbs}
			title={title}
		/>
	);
};
