/**
 * The `pluralize` function returns 's' if the input value is greater than 1.
 * @param {number} value - The `value` parameter is a number that is used to determine whether a plural
 * suffix should be added to a word.
 */
const pluralize = (value: number) => (value > 1 ? 's' : '');

/**
 * The `convertMinutesToHours` function takes a number of minutes as input and returns a formatted string
 * representing the time in hours and minutes.
 * @param {number|undefined} minutes - The `convertMinutesToHours` function takes a number of minutes as input and converts it into
 * a formatted string representing the time in hours and minutes. If the input is less than 60 minutes,
 * it simply returns the minutes with a 'mins' suffix. If the input is 60 minutes or more,
 * @returns {string} The `convertMinutesToHours` function returns a formatted string representing the input time in hours
 * and minutes.
 */
export const convertMinutesToHours = (minutes?: number | string) => {
	if (!minutes) {
		return '';
	}

	let parsedMinutes: number;

	if (typeof minutes === 'string') {
		if (Number.isNaN(Number(minutes))) {
			return '';
		}

		parsedMinutes = parseInt(minutes, 10);
	} else {
		parsedMinutes = minutes;
	}

	if (parsedMinutes < 60) {
		return `${parsedMinutes} min${pluralize(parsedMinutes)}`;
	}

	const hours = Math.floor(parsedMinutes / 60);
	const remainingMinutes = parsedMinutes % 60;

	const pluralizedHours = pluralize(hours);
	const pluralizedMinutes = pluralize(remainingMinutes);

	if (remainingMinutes === 0) {
		return `${hours} hour${pluralizedHours}`;
	}

	return `${hours} hour${pluralizedHours} ${remainingMinutes} min${pluralizedMinutes}`;
};
