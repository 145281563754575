import clsx from 'clsx';
import type { FC } from 'react';
import Caret from '@themaven-net/raven-assets/Caret';
import { Button } from '@/components/raven/ui/Button';
import type { PaginationProps } from '@/types/pagination';
import CaretBack from '@themaven-net/raven-assets/CaretBack';
import { StandardView } from '@/components/raven/ui/Pagination/partials/StandardView';
import { LimitedResultsView } from '@/components/raven/ui/Pagination/partials/LimitedResultsView';

import styles from './styles.module.css';

export const Pagination: FC<PaginationProps> = ({
	anchor = '',
	baseUrl,
	count,
	current,
}) => {
	if (count <= 1) {
		return null;
	}
	const hasLimitedResults = count <= 3;
	const getUrl = (page: number) => {
		if (page === 1) {
			return baseUrl;
		}

		const [base, search] = baseUrl.split('?');

		return [`${base}/page/${page}${anchor ? `${anchor}` : ''}`, search]
			.filter(Boolean)
			.join('?');
	};
	const arrowLeftDisabled = current === 1;
	const arrowRightDisabled = current === count;

	return (
		<nav
			aria-label="Pagination"
			className={clsx(styles.pagination)}
			role="navigation"
		>
			<Button
				aria-label={`Go to page ${current - 1}`}
				disabled={arrowLeftDisabled}
				href={getUrl(current - 1)}
				variation="icon-large-no-fill"
			>
				<CaretBack height={20} width={20} />
			</Button>

			{hasLimitedResults ? (
				<LimitedResultsView count={count} current={current} getUrl={getUrl} />
			) : (
				<StandardView count={count} current={current} getUrl={getUrl} />
			)}

			<Button
				aria-label={`Go to page ${current + 1}`}
				disabled={arrowRightDisabled}
				href={getUrl(current + 1)}
				variation="icon-large-no-fill"
			>
				<Caret height={20} width={20} />
			</Button>
		</nav>
	);
};
