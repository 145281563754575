import type { FC } from 'react';
import { wpKsesPost } from '@headstartwp/core';

import styles from './styles.module.css';

interface DisclaimerProps {
	disclaimer?: string;
}

export const Disclaimer: FC<DisclaimerProps> = ({ disclaimer }) => {
	if (!disclaimer) {
		return null;
	}

	const markup = { __html: wpKsesPost(disclaimer) };

	return (
		<div
			className={styles.disclaimerContainer}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={markup}
		/>
	);
};
