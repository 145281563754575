import clsx from 'clsx';
import type { FC } from 'react';
import type { BlockProps } from '@headstartwp/core/react';
import { ScrollVisible } from '@/components/ScrollVisible';

export const PullQuoteBlock: FC<BlockProps> = ({ block, children, style }) => {
	return (
		<ScrollVisible
			as="figure"
			className={clsx('fade-in-top', block?.className)}
			style={style}
		>
			{children}
		</ScrollVisible>
	);
};
