import type { Element } from 'html-react-parser';
import { safeJSONParse } from '@/utils/json-parse';
import type { RecipeProps } from '@/components/raven/content/Recipe';
import type { RecipeChildBlock, RecipeGroupProps } from '@/types/recipe';

type RecipeInfo = Omit<
	RecipeProps,
	'ingredients' | 'instructions' | 'nutrition'
>;

/**
 * Retrieves the next child class based on the current class.
 *
 * @param currentClass - The current class name.
 * @returns The next child class name if it exists, otherwise `undefined`.
 */
const getNextChildClass = (currentClass: string) => {
	const classMap: Record<string, string | undefined> = {
		'wp-block-the-arena-group-ingredient': undefined,
		'wp-block-the-arena-group-ingredient-group':
			'wp-block-the-arena-group-ingredient',
		'wp-block-the-arena-group-instruction-group':
			'wp-block-the-arena-group-instruction-step',
		'wp-block-the-arena-group-instruction-step': undefined,
	};

	return classMap[currentClass];
};

const getRecipeInfo = (attribs: Record<string, string>): RecipeInfo => {
	return safeJSONParse(attribs['data-wp-block']);
};

/**
 * The function `getRecipeBlocks` processes an array of HTML elements to extract recipe blocks with
 * optional child classes.
 * @param {Element[]} blocks - The `blocks` parameter is an array of Element objects representing
 * different blocks in a recipe. Each block may contain information such as media, title, and possibly
 * child blocks with a specific class. The function `getRecipeBlocks` processes these blocks to extract
 * relevant information and organize them into a structured format for further processing.
 * @param {string} [childClass] - The `childClass` parameter in the `getRecipeBlocks` function is an
 * optional parameter that specifies the class name of the child elements within the blocks. If
 * provided, the function will filter out only the child elements with the specified class name for
 * further processing. If not provided, the function will handle the blocks as standalone elements
 * without any child elements.
 * @returns The `getRecipeBlocks` function returns an array of `RecipeChildBlock<T>` objects. Each
 * object in the array contains either a `media` and `title` property if `childClass` is not provided,
 * or an `items` array and a `title` property if `childClass` is provided. The `items` array contains
 * nested `RecipeChildBlock<T>` objects based on the child elements within the blocks.
 */
const getRecipeBlocks = <T>(
	blocks: Element[],
	childClass?: string,
): RecipeChildBlock<T>[] => {
	return blocks.map((block) => {
		const { media, title } = safeJSONParse(block.attribs['data-wp-block']);

		if (!childClass) {
			if (media) {
				return {
					media,
					title,
				};
			}

			return title;
		}

		const items = block.children.filter(
			(childNode) => (childNode as Element).attribs?.class === childClass,
		) as Element[];

		const nextChildClass = getNextChildClass(childClass);

		return {
			items: getRecipeBlocks<T>(items, nextChildClass) as unknown as T[],
			title,
		};
	});
};

/**
 * The function `getRecipeIngredientsBlock` retrieves recipe ingredient blocks from a given array of
 * elements.
 * @param {Element[]} ingredientsBlock - The `ingredientsBlock` parameter is an array of Element
 * objects representing blocks of ingredients in a recipe.
 * @returns The `getRecipeIngredientsBlock` function is returning a filtered list of recipe blocks that
 * match the 'wp-block-the-arena-group-ingredient-group' type.
 */
const getRecipeIngredientsBlock = (ingredientsBlock: Element[]) => {
	return getRecipeBlocks<RecipeGroupProps>(
		ingredientsBlock,
		'wp-block-the-arena-group-ingredient-group',
	);
};

/**
 * The function `getRecipeInstructionsBlock` retrieves recipe instruction blocks of a specific type
 * from an array of elements.
 * @param {Element[]} instructionsBlock - The `instructionsBlock` parameter is an array of elements
 * that contain the recipe instructions. The function `getRecipeInstructionsBlock` takes this array as
 * input and filters out the elements that match a specific block type
 * (`wp-block-the-arena-group-instruction-group`).
 * @returns The `getRecipeInstructionsBlock` function is returning a filtered list of recipe
 * instruction blocks that match the specified block type 'wp-block-the-arena-group-instruction-group'.
 */
const getRecipeInstructionsBlock = (instructionsBlock: Element[]) => {
	return getRecipeBlocks<RecipeGroupProps>(
		instructionsBlock,
		'wp-block-the-arena-group-instruction-group',
	);
};

/**
 * The function `generateRecipeFromBlock` extracts recipe information from a given DOM node containing
 * ingredients and instructions blocks.
 * @param {Element} [domNode] - The `generateRecipeFromBlock` function takes in a DOM node as a
 * parameter, which represents a block of content from a webpage. The function then extracts recipe
 * information from this block, including ingredients and instructions, and returns a structured recipe
 * object.
 * @returns The `generateRecipeFromBlock` function returns a recipe object that includes recipe
 * information, ingredients, and instructions extracted from the provided `domNode` element. If the
 * `domNode` is not provided, the function returns `null`.
 */
export const generateRecipeFromBlock = (domNode?: Element) => {
	if (!domNode) {
		return null;
	}

	const ingredientsBlock = domNode.children.filter(
		(node) =>
			(node as Element).attribs?.class ===
			'wp-block-the-arena-group-ingredients',
	);

	const instructionsBlock = domNode.children.filter(
		(node) =>
			(node as Element).attribs?.class ===
			'wp-block-the-arena-group-instructions',
	);

	const recipeIngredients = getRecipeIngredientsBlock(
		ingredientsBlock as Element[],
	);

	const recipeInstructions = getRecipeInstructionsBlock(
		instructionsBlock as Element[],
	);

	const recipe = {
		...getRecipeInfo(domNode.attribs),
		ingredients: recipeIngredients,
		instructions: recipeInstructions,
	};

	return recipe;
};
