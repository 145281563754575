import type { FC } from 'react';
import Script from 'next/script';
import type { ListItem, BreadcrumbList } from 'schema-dts';
import type { BreadcrumbItem } from '@/components/raven/content/Breadcrumbs';

interface BreadcrumbsJsonLdProps {
	items: BreadcrumbItem[];
}

export const BreadcrumbsJsonLd: FC<BreadcrumbsJsonLdProps> = ({ items }) => {
	if (!items || items.length === 0) {
		return null;
	}

	const breadcrumbsJsonLd: BreadcrumbList = {
		'@type': 'BreadcrumbList',
		itemListElement: items.map(({ label, link }, index) => {
			return {
				'@type': 'ListItem',
				item: {
					'@id': link,
					name: label,
				},
				position: index + 1,
			} as ListItem;
		}),
	};

	return (
		<Script id="breadcrumbs-ld-json" type="application/ld+json">
			{JSON.stringify({
				'@context': 'https://schema.org',
				...breadcrumbsJsonLd,
			})}
		</Script>
	);
};
