/**
 * The function `replaceButtonBlockClass` takes a list of class names, checks if any class starts with
 * 'is-style-', adds 'is-style-primary' if not present, and replaces 'is-style-' with 'button--' in the
 * class names before returning the modified list as a string.
 * @param {string} classList - classList: a string containing one or more CSS class names separated by
 * spaces.
 * @returns The function `replaceButtonBlockClass` returns a modified list of classes where any class
 * starting with 'is-style-' is replaced with 'button--' and if the list does not contain any class
 * starting with 'is-style-', 'is-style-primary' is added to the list before the modifications are
 * made.
 */
export const replaceButtonBlockClass = (classList: string) => {
	const classes = classList.split(' ');

	const hasStyleClass = classes.some((className) =>
		className.startsWith('is-style-'),
	);

	// The block although is a primary button, it doesn't have the is-style-primary class, so we add it
	if (!hasStyleClass) {
		classes.push('is-style-primary');
	}

	return classes
		.map((className) =>
			className.startsWith('is-style-')
				? className.replace('is-style-', 'button--')
				: className,
		)
		.join(' ');
};
