import type { FC } from 'react';

import styles from './styles.module.css';
import { SUPPORTED_EXTERNAL_VIDEOS } from './constants';

interface ExternalVideoProps {
	url?: string;
}

export const ExternalVideo: FC<ExternalVideoProps> = ({ url }) => {
	const videoIsAllowed = Object.keys(SUPPORTED_EXTERNAL_VIDEOS).some((key) =>
		url?.includes(key),
	);

	if (!url || !videoIsAllowed) {
		return null;
	}

	const videoType = Object.keys(SUPPORTED_EXTERNAL_VIDEOS).find((key) =>
		url.includes(key),
	);

	let videoUrl = url;

	if (videoType === 'youtube') {
		const newUrl = new URL(url);
		newUrl.searchParams.set('enablejsapi', '1');
		videoUrl = newUrl.toString();
	}

	const { title, ...videoAttributes } =
		SUPPORTED_EXTERNAL_VIDEOS[
			videoType as keyof typeof SUPPORTED_EXTERNAL_VIDEOS
		];

	return (
		<div className={styles.videoWrapper}>
			<iframe
				{...videoAttributes}
				aria-label={title}
				src={videoUrl}
				title={title}
			/>
		</div>
	);
};
