import clsx from 'clsx';
import SvgClose from '@themaven-net/raven-assets/Close';
import { useOverlayContext } from '@/context/OverlayContext';
import styles from '@/components/raven/Overlay/styles.module.css';
import { useRef, type FC, useEffect, useCallback, type ReactNode } from 'react';

export interface OverlayProps {
	children?: ReactNode;
	className?: string;
	id?: string;
	onClose?: (e: Event) => void;
	variant: string;
}

export const Overlay: FC<OverlayProps> = ({
	children,
	className,
	id,
	onClose,
	variant,
}) => {
	const { closeVariant, isOpen, registerOverlay, unregisterOverlay } =
		useOverlayContext();
	const overlayRef = useRef<HTMLDialogElement>(null);
	const open = isOpen(variant, id);

	const handleCloseEvent = useCallback(
		(e: Event) => {
			e.preventDefault();
			if (onClose) {
				onClose(e);
				return;
			}
			closeVariant(variant, id);
		},
		[closeVariant, id, onClose, variant],
	);

	useEffect(() => {
		const overlayRefCurrent = overlayRef.current;
		overlayRef.current?.addEventListener('cancel', handleCloseEvent);
		return () => {
			overlayRefCurrent?.removeEventListener('cancel', handleCloseEvent);
		};
	}, [handleCloseEvent]);

	useEffect(() => {
		registerOverlay(variant, id, overlayRef);
		return () => unregisterOverlay(variant, id);
	}, [id, registerOverlay, unregisterOverlay, variant]);

	const cx = clsx(className, styles.overlay, { open });

	return (
		<dialog className={cx} ref={overlayRef}>
			<button
				className={styles.close}
				onClick={() => overlayRef.current?.dispatchEvent(new Event('cancel'))}
				type="button"
			>
				<SvgClose {...{ height: 16, width: 16 }} />
			</button>
			<div className={styles.overlayContent}>{children}</div>
		</dialog>
	);
};
