import type { FC } from 'react';
import type { RecipeSectionProps } from '@/types/recipe';
import styles from '@/components/raven/content/Recipe/styles.module.css';
import { RecipeGroup } from '@/components/raven/content/Recipe/RecipeGroup';

export const RecipeSection: FC<RecipeSectionProps> = ({ items, label }) => {
	if (!items || !items.length) {
		return null;
	}

	return (
		<section aria-label={label} className={styles.recipeSection}>
			{items.map(({ items: section, title }) => {
				const hasSection = section && section.length > 0;

				if (!title && !hasSection) {
					return null;
				}

				return (
					<>
						{title && <h3 className={styles.recipeSectionTitle}>{title}</h3>}

						{hasSection &&
							section.map((group) => (
								<RecipeGroup
									items={group.items}
									key={group.title}
									title={group.title}
								/>
							))}
					</>
				);
			})}
		</section>
	);
};
