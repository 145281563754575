import type { AuthorTaxonomy, ContributorCredits } from '@/types/entities';

/**
 * The function `mapCreditsToAuthors` maps contributor credits to authors based on their IDs.
 * @param {ContributorCredits} [contributorCredits] - The `contributorCredits` parameter is an object
 * that contains credits assigned to contributors. Each key in the object represents a contributor ID,
 * and the corresponding value is the credit assigned to that contributor.
 * @param {AuthorTaxonomy[] | null} [authors] - The `mapCreditsToAuthors` function takes two
 * parameters:
 * @returns The function `mapCreditsToAuthors` returns a Map object that maps contributor credits to
 * arrays of AuthorTaxonomy objects. If there are no authors or contributor credits provided, or if the
 * arrays are empty, the function returns null.
 */
export const mapCreditsToAuthors = (
	contributorCredits?: ContributorCredits,
	authors?: AuthorTaxonomy[] | null,
) => {
	if (
		!authors ||
		authors.length === 0 ||
		!contributorCredits ||
		(Array.isArray(contributorCredits) && contributorCredits.length === 0)
	) {
		return null;
	}

	const creditsMap = new Map<string, AuthorTaxonomy[]>();

	Object.entries(contributorCredits).forEach(([id, credit]) => {
		const author = authors.find((a) => a.id === Number(id));

		if (author) {
			if (!creditsMap.has(credit)) {
				creditsMap.set(credit, []);
			}

			creditsMap.get(credit)!.push(author);
		}
	});

	return creditsMap.size > 0 ? creditsMap : null;
};
