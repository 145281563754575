import type { FC } from 'react';
import type { CtaProps } from '@/types/cta';
import type { PostLookup } from '@/types/entities';
import type { RavenIcon } from '@/utils/get-icons';
import type { parsePath } from '@/utils/parse-path';
import type { ApiResponse } from '@/utils/data/types';
import { ArticleGrid } from '@/components/ArticleGrid';
import type { CustomPostEntity } from '@/types/blocks';
import type { BlockProps } from '@headstartwp/core/react';
import { serializePostLookup } from '@/utils/post-helpers';
import type { ArticleGridVariation } from '@/types/articles';
import { Pagination } from '@/components/raven/ui/Pagination';
import { CarouselHero } from '@/components/raven/content/Carousels/CarouselHero';
import { CarouselTiles } from '@/components/raven/content/Carousels/CarouselTiles';
import {
	generateArticleTiles,
	type ArticlesTilesOptions,
} from '@/utils/generate-article-tiles';

interface PostBlockAttributes {
	aspectRatio: `${number}:${number}`;
	autoRotate: boolean;
	ctaIcon?: RavenIcon;
	ctaLink: CtaProps;
	ctaStyle?: 'link' | 'primary' | 'secondary';
	curationType: 'automatic' | 'manual';
	delay: number;
	hideAuthor: boolean;
	hideCategory: boolean;
	hideDate: boolean;
	hideTags: boolean;
	pagination: boolean;
	postLayout: string;
	posts: CustomPostEntity[];
	title: string;
	titleLevel: 1 | 2 | 3;
	variation: string;
}

type PostsBlockProps = BlockProps;

export const PostsBlock: FC<PostsBlockProps> = ({ block, blockContext }) => {
	if (!block) {
		return null;
	}

	const {
		aspectRatio,
		autoRotate,
		ctaIcon,
		ctaLink,
		ctaStyle,
		curationType,
		delay,
		hideAuthor,
		hideCategory,
		hideDate,
		hideTags,
		pagination,
		postLayout,
		title,
		titleLevel,
		variation,
	} = block.attributes as unknown as PostBlockAttributes;

	const parsedPath = blockContext?.parsedPath as ReturnType<typeof parsePath>;
	const isContentCard = variation === 'content-card';
	const isHeroCarousel = variation === 'hero-carousel';
	const isPostsCollage = variation === 'posts-collage';
	const isCarouselTiles = variation === 'carousel-tiles';
	const isGridWall = postLayout === 'gridwall-4-up';

	const baseUrl = `/${parsedPath.basePath.join('/')}`;
	const hasPagination =
		curationType === 'automatic' && pagination && Boolean(baseUrl);
	let totalPages = 0;

	const postVariation =
		postLayout === 'split' || isContentCard ? 'horizontal' : 'stacked';
	let gridVariation: ArticleGridVariation =
		isPostsCollage || isContentCard
			? (variation as 'content-card' | 'posts-collage')
			: 'standard';

	if (isGridWall) {
		gridVariation = 'gridwall';
	}

	const articleOptions = {
		aspectRatio,
		hideAuthor,
		hideCategory,
		hideDate,
		hideTags,
		postVariation,
	} as ArticlesTilesOptions;

	let posts: CustomPostEntity[] = Array.isArray(block.attributes.posts)
		? block.attributes.posts
		: [];

	if (!posts || posts.length === 0) {
		if (curationType === 'automatic') {
			const key = serializePostLookup(
				block.attributes as unknown as PostLookup,
				parsedPath.page,
			);

			const uniqueBlocks = (blockContext?.uniqueBlocks || {}) as Record<
				string,
				ApiResponse<CustomPostEntity[]>
			>;

			if (blockContext && key in uniqueBlocks) {
				const data = uniqueBlocks[key];
				totalPages = Number(data.headers['x-wp-totalpages']);
				posts = data.json;
			}
		}
	}

	if (!posts || posts.length === 0) {
		return null;
	}

	const articles = generateArticleTiles(posts, articleOptions);

	if (isHeroCarousel) {
		return (
			<CarouselHero
				autoRotate={autoRotate}
				cta={ctaLink}
				delay={delay}
				posts={articles}
			/>
		);
	}

	if (isCarouselTiles) {
		return (
			<CarouselTiles
				cta={ctaLink}
				ctaIcon={ctaIcon}
				ctaStyle={ctaStyle}
				posts={articles}
				title={title}
				titleLevel={titleLevel}
			/>
		);
	}

	return (
		<>
			<ArticleGrid
				className="custom-block"
				cta={ctaLink}
				ctaIcon={ctaIcon}
				ctaStyle={ctaStyle}
				gridVariation={gridVariation}
				posts={articles}
				title={title}
				variation={postVariation}
			/>
			{hasPagination && (
				<Pagination
					anchor=""
					baseUrl={baseUrl}
					count={totalPages}
					current={parsedPath.page || 1}
				/>
			)}
		</>
	);
};
