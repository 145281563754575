'use client';

import type React from 'react';
import { useSiteContext } from '@/context/SiteContext';
import { useLoadScript } from '@/context/ScriptsContext';
import { useAdService } from '@/context/AdServiceContext';
import { useAppSettings } from '@/context/AppSettingsContext';
import { serializeExperiments } from '@/utils/serialize-experiments';
import { LAUNCHER_SCRIPT_SELECTOR } from '@/components/raven/Comments/OpenWeb';

export const LAUNCHER_SCRIPT_BASE_URL = 'https://launcher.spot.im/spot';
const voidFn = () => {};
const initOW = () => {
	window.__OPEN_WEB__?.initOW();
};

export const LoaderWrapper: React.FC<{
	authorId?: string;
	children?: React.ReactNode;
	spotId: string;
}> = ({ authorId, children, spotId }) => {
	const { experiments } = useAdService();
	const appSettings = useAppSettings();
	const { config } = useSiteContext();
	const isDarkTheme = appSettings?.settings?.appearance?.theme === 'dark';
	const experimentString = serializeExperiments(experiments);

	// Prepare data attributes for the script
	const dataAttributes: Record<string, string> = {
		'custom-author-id': authorId || '',
		'custom-channel-id': config.channelKey,
		'custom-experiment-ids': experimentString || 'null',
		'custom-site-id': config.siteId,
		'seo-enabled': 'true',
		'spotim-autorun': 'false',
	};
	if (isDarkTheme) {
		dataAttributes.theme = 'dark';
	}

	// Load the script
	useLoadScript(
		LAUNCHER_SCRIPT_SELECTOR,
		`${LAUNCHER_SCRIPT_BASE_URL}/${spotId}`,
		initOW,
		initOW,
		voidFn,
		dataAttributes,
	);

	return spotId ? children : null;
};
