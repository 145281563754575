import type { FC } from 'react';
import { Image } from '@/components/raven/Image';
import { SafeHtml } from '@headstartwp/core/react';
import type { InstructionItemProps } from '@/types/recipe';
import styles from '@/components/raven/content/Recipe/styles.module.css';

interface CommonListItemProps {
	className: string;
	itemProp: string;
}

interface RecipeIngredientItem extends CommonListItemProps {
	media?: never;
	title: string;
}

interface RecipeInstructionItem
	extends CommonListItemProps,
		InstructionItemProps {}

export type RecipeItemProps = RecipeIngredientItem | RecipeInstructionItem;

export const RecipeItem: FC<RecipeItemProps> = ({
	className,
	itemProp,
	media,
	title,
}) => {
	if (!title) {
		return null;
	}

	return (
		<li className={className} itemProp={itemProp}>
			<p>
				<SafeHtml html={title} />
			</p>

			{media && media.length > 0 && (
				<div className={styles.recipeInstructionsMedia}>
					{media?.map((mediaItem) => (
						<Image
							alt={mediaItem.alt || ''}
							className={styles.recipeInstructionsImage}
							height={702}
							key={mediaItem.id}
							sizes="(max-width: 768px) 700px, 100vw"
							src={mediaItem.url}
							width={702}
						/>
					))}
				</div>
			)}
		</li>
	);
};
