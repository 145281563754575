import type { FC } from 'react';
import { SafeHtml } from '@headstartwp/core/react';
import { RecipeJsonLd } from '@/components/raven/content/Recipe/RecipeJsonLd';
import { RecipeDetails } from '@/components/raven/content/Recipe/RecipeDetails';
import { RecipeSection } from '@/components/raven/content/Recipe/RecipeSection';
import { NutritionFacts } from '@/components/raven/content/Recipe/NutritionFacts';
import type { RecipeDetailItemProps } from '@/components/raven/content/Recipe/RecipeDetails/RecipeDetailItem';
import type {
	RecipeChildBlock,
	RecipeGroupProps,
	RecipeDetailsProps,
	NutritionFactsProps,
} from '@/types/recipe';

import styles from './styles.module.css';

export interface RecipeProps extends RecipeDetailsProps {
	attribution?: string;
	description?: string;
	featuredImage?: string;
	ingredients: RecipeChildBlock<RecipeGroupProps>[];
	instructions: RecipeChildBlock<RecipeGroupProps>[];
	legacyNutritionFacts?: string;
	nutrition?: NutritionFactsProps;
	postTitle: string;
	title: string;
}

export const Recipe: FC<RecipeProps> = ({
	attribution,
	cookTime,
	description,
	featuredImage,
	ingredients,
	instructions,
	keywords,
	legacyNutritionFacts,
	nutrition,
	postTitle,
	prepTime,
	servings,
	title,
	totalTime,
}) => {
	const recipeDetails: RecipeDetailItemProps[] = [
		{
			icon: 'Clock',
			itemProp: 'totalTime',
			label: 'Duration',
			value: totalTime,
		},
		{
			icon: 'Clock',
			itemProp: 'cookTime',
			label: 'Cook Time',
			value: cookTime,
		},
		{
			icon: 'Servings',
			itemProp: 'recipeYield',
			label: 'Servings',
			needsFormatting: false,
			value: servings,
		},
		{
			icon: 'Clock',
			itemProp: 'prepTime',
			label: 'Prep Time',
			value: prepTime,
		},
	];

	return (
		<article
			className={styles.recipe}
			itemScope
			itemType="https://schema.org/Recipe"
		>
			<header className={styles.recipeHeader}>
				{title && (
					<h2 className={styles.recipeTitle} itemProp="name">
						{title}
					</h2>
				)}

				{description && (
					<p className={styles.recipeDescription} itemProp="description">
						<SafeHtml html={description} />
					</p>
				)}

				<RecipeDetails details={recipeDetails} />
			</header>

			<RecipeSection items={ingredients} label="Ingredients" />

			<RecipeSection items={instructions} label="Instructions" />

			<NutritionFacts
				legacyNutritionFacts={legacyNutritionFacts}
				nutritionFacts={nutrition}
			/>

			{attribution && (
				<footer className={styles.recipeFooter}>
					<p className={styles.recipeAttribution}>
						<SafeHtml html={attribution} />
					</p>
				</footer>
			)}

			<RecipeJsonLd
				{...{
					cookTime,
					description,
					featuredImage,
					ingredients,
					instructions,
					keywords,
					nutrition,
					postTitle,
					prepTime,
					servings,
					title,
					totalTime,
				}}
			/>
		</article>
	);
};
