import type { FC } from 'react';
import type { CtaProps } from '@/types/cta';
import { Cta } from '@/components/raven/Cta';
import type { RavenIcon } from '@/utils/get-icons';
import type { BlockProps } from '@headstartwp/core/react';
import { replaceButtonBlockClass } from '@/utils/replace-button-block-class';

interface ButtonBlockAttributes {
	cta: CtaProps;
	icon?: RavenIcon;
}

export const ButtonBlock: FC<BlockProps> = ({ block }) => {
	if (!block) {
		return null;
	}

	const { cta, icon } = block.attributes as unknown as ButtonBlockAttributes;

	const buttonClass = replaceButtonBlockClass(block.className);

	return <Cta cta={cta} icon={icon} linkClassName={buttonClass} />;
};
