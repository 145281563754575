import type { FC } from 'react';
import Script from 'next/script';
import type { Recipe as RecipeSchema } from 'schema-dts';
import { formatRecipeTime } from '@/utils/recipe/format-recipe-time';
import type { RecipeProps } from '@/components/raven/content/Recipe';
import type { NutritionFactsMap, InstructionItemProps } from '@/types/recipe';
import { nutritionFactToUnitMap } from '@/components/raven/content/Recipe/NutritionFacts';

export const RecipeJsonLd: FC<RecipeProps> = ({
	cookTime,
	description,
	featuredImage,
	ingredients,
	instructions,
	keywords,
	nutrition,
	postTitle,
	prepTime,
	servings,
	title,
	totalTime,
}) => {
	const hasIngredients = ingredients && ingredients.length > 0;
	const hasInstructions = instructions && instructions.length > 0;
	const hasNutrition = nutrition && Object.keys(nutrition).length > 0;

	const recipeJsonLd: RecipeSchema = {
		'@type': 'Recipe',
		cookTime: formatRecipeTime(cookTime),
		description,
		image: featuredImage,
		keywords: keywords || '',
		name: title || postTitle,
		prepTime: formatRecipeTime(prepTime),
		recipeYield: servings ? `${servings} servings` : '',
		totalTime: formatRecipeTime(totalTime),
	};

	if (hasIngredients) {
		recipeJsonLd.recipeIngredient = ingredients
			.flatMap((group) => group.items)
			.flatMap((item) => item?.items as string[])
			.filter((ingredient): ingredient is string => ingredient !== undefined);
	}

	if (hasInstructions) {
		recipeJsonLd.recipeInstructions = instructions
			.flatMap((group) => group.items)
			.flatMap((item) => item?.items as InstructionItemProps[])
			.filter((step): step is { title: string } => step !== undefined)
			.map((step) => ({
				'@type': 'HowToStep',
				text: step.title,
			}));
	}

	if (hasNutrition) {
		const nutritionValues = Object.entries(nutrition).map(([key, value]) => {
			const unit = nutritionFactToUnitMap[key as NutritionFactsMap];
			return [key, `${value}${unit}`];
		});

		recipeJsonLd.nutrition = {
			'@type': 'NutritionInformation',
			...Object.fromEntries(nutritionValues),
		};
	}

	return (
		<Script id="recipe-json-ld" type="application/ld+json">
			{JSON.stringify({
				'@context': 'https://schema.org',
				...recipeJsonLd,
			})}
		</Script>
	);
};
