import type { PaginationViewProps } from '@/types/pagination';
import { Ellipses } from '@/components/raven/ui/Pagination/partials/Ellipses';
import { PaginationLink } from '@/components/raven/ui/Pagination/partials/PaginationLink';

import styles from '../styles.module.css';

export const StandardView = ({
	count,
	current,
	getUrl,
}: PaginationViewProps) => {
	return (
		<div className={styles.paginationWidget}>
			{current > 1 && <PaginationLink getUrl={getUrl} number={1} />}
			{current - 2 > 1 && (
				<>
					<Ellipses hideDesktop={current - 3 <= 1} testId="ellipses-left" />
					<PaginationLink getUrl={getUrl} isFlanker number={current - 2} />
				</>
			)}
			{current - 1 > 1 && (
				<PaginationLink getUrl={getUrl} number={current - 1} />
			)}

			<PaginationLink getUrl={getUrl} isCurrent number={current} />

			{current + 1 < count && (
				<PaginationLink getUrl={getUrl} number={current + 1} />
			)}
			{current + 2 < count && (
				<>
					<PaginationLink getUrl={getUrl} isFlanker number={current + 2} />
					<Ellipses
						hideDesktop={count - (current + 2) <= 1}
						testId="ellipses-right"
					/>
				</>
			)}
			{current !== count && <PaginationLink getUrl={getUrl} number={count} />}
		</div>
	);
};
