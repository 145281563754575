import type { Article } from '@/components/ArticleList/types';
import { type FC, createContext, type ReactNode } from 'react';
import { AdVisibilityProvider } from '@/context/AdVisibilityContext';

export const ArticleListContext = createContext<{ articles: Article[] }>({
	articles: [],
});
export const ArticleListProvider: FC<{
	articles: Article[];
	children: ReactNode;
}> = ({ articles, children }) => (
	// eslint-disable-next-line react/jsx-no-constructed-context-values
	<ArticleListContext.Provider value={{ articles }}>
		<AdVisibilityProvider>{children}</AdVisibilityProvider>
	</ArticleListContext.Provider>
);
