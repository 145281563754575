import clsx from 'clsx';
import type { FC } from 'react';
import { InternalLink } from '@/components/InternalLink';

import styles from './styles.module.css';

export interface TableOfContentsProps {
	headings: {
		anchor: string;
		label: string;
	}[];
	includeBackground?: boolean;
	includeNumbers?: boolean;
	title?: string;
}

const formatNumber = (number: number) => number.toString().padStart(2, '0');

export const TableOfContents: FC<TableOfContentsProps> = ({
	headings,
	includeBackground,
	includeNumbers,
	title,
}) => {
	if (!headings.length) {
		return null;
	}

	const className = clsx(styles.tableOfContents, {
		[styles.tableOfContentsBackground]: includeBackground,
	});

	return (
		<nav aria-label="Table of Contents" className={className}>
			{title && <h2 className={styles.tableOfContentsTitle}>{title}</h2>}
			<ol className={styles.tableOfContentsList}>
				{headings.map((heading, index) => (
					<li
						className={styles.tableOfContentsListItem}
						key={`${heading.label}-${formatNumber(index + 1)}`}
					>
						<InternalLink
							className={styles.tableOfContentsLink}
							href={heading.anchor}
						>
							<span className={styles.tableOfContentsItemLabel}>
								{heading.label}
							</span>
							{includeNumbers && (
								<span className={styles.tableOfContentsItemNumber}>
									{formatNumber(index + 1)}
								</span>
							)}
						</InternalLink>
					</li>
				))}
			</ol>
		</nav>
	);
};
