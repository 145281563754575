import clsx from 'clsx';
import type { FC } from 'react';
import type { EmbedBlockProps } from '@/types/embeds';
import { ScrollVisible } from '@/components/ScrollVisible';
import { EmbedGDPR } from '@/components/raven/Blocks/embed/EmbedGDPR';
import { RedditEmbedWrapper } from '@/components/raven/Blocks/embed/RedditEmbedBlock/RedditEmbedWrapper';

import styles from './styles.module.css';

export const RedditEmbedBlock: FC<EmbedBlockProps> = ({
	block,
	children,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the GDPR and cause a Next.js error
	...rest
}) => {
	const className = clsx(block?.className, styles.wrapper, 'fade-in-top');

	return (
		<EmbedGDPR
			purposes={['content']}
			vendorId="reddit"
			{...rest}
			url={(block?.attributes.url || '') as string}
		>
			<RedditEmbedWrapper url={(block?.attributes.url || '') as string}>
				<ScrollVisible className={className}>{children}</ScrollVisible>
			</RedditEmbedWrapper>
		</EmbedGDPR>
	);
};
