import type { FC, ReactNode } from 'react';

export const ImageSource: FC<{
	className?: string;
	sourceName: string;
	sourceUrl?: string;
}> = ({ className, sourceName, sourceUrl }) => {
	if (!sourceName) {
		return null;
	}

	let markup: ReactNode = sourceName;

	if (sourceUrl) {
		markup = (
			<a
				aria-label={`Go to image source: ${sourceName}`}
				href={sourceUrl}
				rel="noreferrer"
				target="_blank"
			>
				{sourceName}
			</a>
		);
	}

	return <span className={className}>{markup}</span>;
};
