'use client';

import { events$ } from '@/utils/events';
import { useRef, type FC, useEffect, type ReactNode } from 'react';
import {
	onEnterFilter,
	onLeaveFilter,
	fromIntersection,
	type Subscription,
} from '@/utils/from-intersection';

export type ScrollEventHandler = (e: IntersectionObserverEntry) => unknown;

export interface ScrollSentinelProps {
	children: ReactNode;
	headRootMargin?: string;
	onHeadEnter?: ScrollEventHandler;
	onHeadLeave?: ScrollEventHandler;
	onTailEnter?: ScrollEventHandler;
	onTailLeave?: ScrollEventHandler;
	tailRootMargin?: string;
}
export const ScrollSentinel: FC<ScrollSentinelProps> = ({
	children,
	headRootMargin = '0px 0px 0px 0px',
	onHeadEnter,
	onHeadLeave,
	onTailEnter,
	onTailLeave,
	tailRootMargin = '0px 0px 0px 0px',
}) => {
	const hasHead = Boolean(onHeadEnter || onHeadLeave);
	const hasTail = Boolean(onTailEnter || onTailLeave);
	const headRef = useRef(null);
	const tailRef = useRef(null);
	useEffect(() => {
		const subscriptions: Subscription[] = [];
		if (hasHead && headRef.current) {
			const headIntersection$ = fromIntersection(headRef.current, {
				rootMargin: headRootMargin,
			});
			if (onHeadEnter) {
				subscriptions.push(
					headIntersection$
						.pipe(onEnterFilter)
						.subscribe((entry: IntersectionObserverEntry) => {
							events$.next({
								type: 'scroll-sentinel-head-entry',
								value: entry,
							});
							onHeadEnter(entry);
						}),
				);
			}
			if (onHeadLeave) {
				subscriptions.push(
					headIntersection$
						.pipe(onLeaveFilter)
						.subscribe((entry: IntersectionObserverEntry) => {
							events$.next({
								type: 'scroll-sentinel-head-leave',
								value: entry,
							});
							onHeadLeave(entry);
						}),
				);
			}
		}
		return () => {
			subscriptions.forEach((s) => s.unsubscribe());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [headRootMargin, headRef.current, onHeadEnter, onHeadLeave]);

	useEffect(() => {
		const subscriptions: Subscription[] = [];
		if (hasTail && tailRef.current) {
			const tailIntersection$ = fromIntersection(tailRef.current, {
				rootMargin: tailRootMargin,
			});
			if (onTailEnter) {
				subscriptions.push(
					tailIntersection$
						.pipe(onEnterFilter)
						.subscribe((entry: IntersectionObserverEntry) => {
							events$.next({
								type: 'scroll-sentinel-tail-entry',
								value: entry,
							});
							onTailEnter(entry);
						}),
				);
			}
			if (onTailLeave) {
				subscriptions.push(
					tailIntersection$
						.pipe(onLeaveFilter)
						.subscribe((entry: IntersectionObserverEntry) => {
							events$.next({
								type: 'scroll-sentinel-tail-leave',
								value: entry,
							});
							onTailLeave(entry);
						}),
				);
			}
		}
		return () => {
			subscriptions.forEach((s) => s.unsubscribe());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tailRootMargin, tailRef.current, onTailEnter, onTailLeave]);

	return (
		<>
			{hasHead && <span className="scroll-sentinel-head" ref={headRef} />}
			{children}
			{hasTail && <span className="scroll-sentinel-tail" ref={tailRef} />}
		</>
	);
};
