import type { FC } from 'react';
import type { AnalyticsData } from '@/types/entities';
import type { EmbedBlockProps } from '@/types/embeds';
import { VideoPlayer } from '@/components/VideoPlayer';
import { ScrollVisible } from '@/components/ScrollVisible';
import {
	LazyLoadWrapper,
	type LazyLoadWrapperProps,
} from '@/components/LazyLoadWrapper';

type VideoBlockProps = {
	analyticsData: AnalyticsData;
} & EmbedBlockProps &
	LazyLoadWrapperProps;

interface VideoBlockAttributes {
	auto_play_muted: boolean;
	embed_url: string;
	platform_id: string;
	thumbnail_url: string;
	video_title: string;
	videoSrc: string;
}

export const VideoBlock: FC<VideoBlockProps> = ({
	analyticsData,
	block,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the LazyLoadWrapper and cause a Next.js error
	...rest
}) => {
	if (!block) {
		return null;
	}

	const {
		auto_play_muted: disableAutoplay,
		platform_id: videoId,
		thumbnail_url: thumbnailUrl,
		video_title: videoTitle,
		videoSrc,
	} = block.attributes as unknown as VideoBlockAttributes;

	if (!videoSrc) {
		return null;
	}

	return (
		<LazyLoadWrapper {...rest}>
			<ScrollVisible className="fade-in-top">
				<VideoPlayer
					analyticsData={analyticsData}
					disableAutoplay={disableAutoplay}
					videoId={videoId}
					videoImage={thumbnailUrl}
					videoSrc={videoSrc}
					videoTitle={videoTitle}
				/>
			</ScrollVisible>
		</LazyLoadWrapper>
	);
};
