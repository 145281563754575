import type { FC } from 'react';
import type { Element } from 'html-react-parser';
import { safeJSONParse } from '@/utils/json-parse';
import type { BlockProps } from '@headstartwp/core/react';
import { FallbackVideo } from '@/components/FallbackVideo';

/**
 * FallbackVideoBlock React component.
 * This is a server-side component which returns markup for the client-side fallback video player,
 * which could be an ExCoPlayer or JWPlayer component.
 * Expected attributes on the provided `domNode`:
 * - data-smart-slot-config: The configuration for the ad slot (identifies breakpoint, zone, and index).
 * @param {Object} props - The props object, which includes a domNode.
 */
export const FallbackVideoBlock: FC<BlockProps> = ({ domNode }) => {
	if (!domNode || !domNode.attribs) {
		return null;
	}
	const { 'data-smart-slot-config': slotConfig } = domNode.attribs;
	if (!slotConfig) {
		return null;
	}
	const { type } = safeJSONParse(slotConfig);
	return <FallbackVideo type={type} />;
};

export const isFallbackVideo = (node: Element) => {
	if (
		node.name === 'div' &&
		node.attribs?.['data-smart-slot'] === 'fallback-video'
	) {
		// Assign these to the node attributes to suppress 10up warning that this isn't
		// a recognized block.
		// eslint-disable-next-line no-param-reassign
		node.attribs['data-wp-block-name'] = 'arena/fallback-video';
		// eslint-disable-next-line no-param-reassign
		node.attribs['data-wp-block'] = '';
		return true;
	}
	return false;
};
