import type { FC } from 'react';
import Script from 'next/script';
import type { EmbedBlockProps } from '@/types/embeds';

import './poll-daddy-styles.css';
import { EmbedGDPR } from '@/components/raven/Blocks/embed/EmbedGDPR';

export const PollDaddyEmbedBlock: FC<EmbedBlockProps> = ({
	block,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the GDPR and cause a Next.js error
	...rest
}) => {
	const { url } = block?.attributes as { url: string };
	if (!url) {
		return null;
	}

	const match = url.match(/\/(\d+)$/);

	if (!match) {
		return null;
	}

	const pollId = match[1];

	return (
		<EmbedGDPR
			purposes={['content']}
			vendorId="polldaddy"
			{...rest}
			url={block?.attributes.url}
		>
			<div className="PDS_Poll" id={`PDI_container${pollId}`} />
			<Script src={`https://secure.polldaddy.com/p/${pollId}.js`} />
		</EmbedGDPR>
	);
};
