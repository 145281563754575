import clsx from 'clsx';
import Link from 'next/link';
import type { FC } from 'react';
import { HtmlDecoder } from '@headstartwp/core/react';
import { BreadcrumbsJsonLd } from '@/components/raven/content/Breadcrumbs/BreadcrumbsJsonLd';

import styles from './styles.module.css';

export interface BreadcrumbItem {
	label: string;
	link?: string;
}

export interface BreadcrumbsProps {
	className?: string;
	items?: BreadcrumbItem[];
	listClassName?: string;
	listItemClassName?: string;
	listItemLinkClassName?: string;
	separatorClassName?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
	className,
	items,
	listClassName,
	listItemClassName,
	listItemLinkClassName,
	separatorClassName,
}) => {
	if (!items || items.length === 0) {
		return null;
	}

	const renderedItems = items.slice(0, -1);

	if (renderedItems.length === 1) {
		return <BreadcrumbsJsonLd items={items} />;
	}

	return (
		<nav
			aria-label="Breadcrumb"
			className={clsx(styles.breadcrumbsContainer, className)}
		>
			<BreadcrumbsJsonLd items={items} />

			<ol className={clsx(styles.breadcrumbsList, listClassName)}>
				{renderedItems.map(({ label, link }, index) => {
					const isLast = index === renderedItems.length - 1;
					const safeLabel = <HtmlDecoder html={label} />;

					return (
						<li
							className={clsx(styles.breadcrumbsItem, listItemClassName)}
							key={label}
						>
							{link ? (
								<Link
									aria-label={`Go to: ${label}`}
									className={clsx(
										styles.breadcrumbsItemLink,
										listItemLinkClassName,
									)}
									href={link}
								>
									{safeLabel}
								</Link>
							) : (
								<span>{safeLabel}</span>
							)}
							{!isLast && <span className={separatorClassName}>/</span>}
						</li>
					);
				})}
			</ol>
		</nav>
	);
};
