'use client';

import { isClient } from '@/utils/is-client';
import { map, filter, pairwise } from 'rxjs/operators';
import {
	empty,
	Observable,
	type Observer,
	type Subscription as RxSubscription,
} from 'rxjs';

export type Subscription = RxSubscription;
export type IntersectionObservable = Observable<IntersectionObserverEntry>;
export const fromIntersection = (
	target: Element,
	options: IntersectionObserverInit,
): Observable<IntersectionObserverEntry> =>
	isClient
		? new Observable<IntersectionObserverEntry>(
				(observer: Observer<IntersectionObserverEntry>) => {
					const intersectionObserver = new window.IntersectionObserver(
						(entries) => {
							if (entries.length > 0) {
								entries.forEach((entry) => observer.next(entry));
							}
						},
						options,
					);
					intersectionObserver.observe(target);
					return () => {
						intersectionObserver.disconnect();
					};
				},
			)
		: empty();

export const onEnterFilter = (intersection$: IntersectionObservable) =>
	intersection$.pipe(
		pairwise(),
		filter(
			([prev, curr]: [IntersectionObserverEntry, IntersectionObserverEntry]) =>
				!prev.isIntersecting && curr.isIntersecting,
		),
		map(
			([, currentEntry]: [
				IntersectionObserverEntry,
				IntersectionObserverEntry,
			]) => currentEntry,
		),
	);

export const onLeaveFilter = (intersection$: IntersectionObservable) =>
	intersection$.pipe(
		pairwise(),
		filter(
			([prev, curr]: [IntersectionObserverEntry, IntersectionObserverEntry]) =>
				prev.isIntersecting && !curr.isIntersecting,
		),
		map(
			([, currentEntry]: [
				IntersectionObserverEntry,
				IntersectionObserverEntry,
			]) => currentEntry,
		),
	);
