import type { FC } from 'react';
import type { PostEntity } from '@headstartwp/core';
import { ArticleTile } from '@/components/ArticleTile';
import type { ArticleTileProps } from '@/types/articles';
import { CarouselDefault } from '@/components/raven/content/Carousels/CarouselDefault';

export interface CarouselCategoryProps {
	addStroke: boolean;
	className?: string;
	slides: ArticleTileProps[];
	terms: PostEntity[];
	title: string;
}

export const CarouselCategory: FC<CarouselCategoryProps> = ({
	addStroke,
	className,
	slides,
	terms,
	title,
}) => {
	if (!terms || !Array.isArray(terms) || terms.length === 0) {
		return null;
	}

	return (
		<CarouselDefault
			className={className}
			slides={slides}
			slidesToScroll={6}
			slidesToScrollMobile={2}
			title={title}
			variation="carousel-category"
		>
			{slides.map((slide, index) => {
				return (
					<div className="carousel__slide" key={slide.id}>
						<ArticleTile addStroke={addStroke} {...slide} />
					</div>
				);
			})}
		</CarouselDefault>
	);
};
