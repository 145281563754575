import type { FC } from 'react';
import { Avatar } from '@/components/raven/ui/Avatar';
import type { AuthorTaxonomy } from '@/types/entities';
import { InternalLink } from '@/components/InternalLink';
import { decodeAndStripTags } from '@/utils/decode-and-strip-tags';
import { Credits } from '@/components/raven/content/Byline/Credits';
import { ContributorsList } from '@/components/raven/content/Byline/ContributorsList';

import styles from './styles.module.css';

export interface ByLineProps {
	contributors: AuthorTaxonomy[];
	credits?: Map<string, AuthorTaxonomy[]> | null;
	showMultipleAvatars?: boolean;
}

const SingleContributor: FC<AuthorTaxonomy & Pick<ByLineProps, 'credits'>> = ({
	avatar,
	credits,
	link,
	name,
	role,
}) => {
	const safeAuthorName = decodeAndStripTags(name);

	return (
		<>
			{avatar && <Avatar priority src={avatar} variant="small" />}
			<div className={styles.contributorContainer}>
				<div className={styles.singleContributorContainer}>
					<div className={styles.contributorNameContainer}>
						By{' '}
						<InternalLink
							aria-label={`Read more articles from ${safeAuthorName}`}
							className={styles.contributorName}
							href={link}
						>
							{safeAuthorName}
						</InternalLink>
					</div>
					{role && <span className={styles.contributorRole}>{role}</span>}
				</div>

				{credits && <Credits credits={credits} />}
			</div>
		</>
	);
};

const MultipleContributors: FC<ByLineProps> = ({
	contributors,
	credits,
	showMultipleAvatars,
}) => {
	const hasAvatars = contributors.some((contributor) => contributor.avatar);

	return (
		<>
			{hasAvatars && (
				<ul aria-label="Contributors Images" className={styles.avatarsList}>
					{showMultipleAvatars ? (
						contributors.map(({ avatar, name }) => (
							<li className={styles.avatarListItem} key={name}>
								<Avatar priority src={avatar} variant="xsmall" />
							</li>
						))
					) : (
						<li className={styles.avatarListItem}>
							<Avatar priority src={contributors[0].avatar} variant="xsmall" />
						</li>
					)}
				</ul>
			)}

			<div className={styles.contributorContainer}>
				<div className={styles.contributorNameContainer}>
					By <ContributorsList contributors={contributors} />
				</div>

				{credits && <Credits credits={credits} />}
			</div>
		</>
	);
};

export const Byline: FC<ByLineProps> = ({
	contributors,
	credits,
	showMultipleAvatars = false,
}) => {
	if (!contributors || !contributors.length) {
		return null;
	}

	const isSingleContributor = contributors.length === 1;
	const visibleContributors = isSingleContributor
		? contributors
		: contributors.slice(0, 3);

	return (
		<div className={styles.contributors}>
			{isSingleContributor ? (
				<SingleContributor {...visibleContributors[0]} credits={credits} />
			) : (
				<MultipleContributors
					contributors={visibleContributors}
					credits={credits}
					showMultipleAvatars={showMultipleAvatars}
				/>
			)}
		</div>
	);
};
