import type { PaginationViewProps } from '@/types/pagination';
import { PaginationLink } from '@/components/raven/ui/Pagination/partials/PaginationLink';

import styles from '../styles.module.css';

export const LimitedResultsView = ({
	count,
	current,
	getUrl,
}: PaginationViewProps) => {
	return (
		<div className={styles.paginationWidget}>
			{Array(count)
				.fill(null)
				.map((_, i) => {
					return (
						<PaginationLink
							getUrl={getUrl}
							isCurrent={i + 1 === current}
							key={`pagination-limited-result-${i + 1}`}
							number={i + 1}
						/>
					);
				})}
		</div>
	);
};
