import clsx from 'clsx';
import type { FC, ElementType } from 'react';
import { Image } from '@/components/raven/Image';
import type { StaticImageData } from 'next/image';
import type { BlockProps, IDataWPBlock } from '@headstartwp/core/react';

import styles from './styles.module.css';

export interface QuoteBlockAttributes extends IDataWPBlock {
	aspectRatio?: '1-1' | '4-5';
	focalPoint?: { x: number; y: number };
	layout: 'attribution-photo' | 'full-bleed' | 'text-only';
	media?: {
		alt: string;
		caption: string;
		id: string;
		url: StaticImageData | string;
	};
	mediaAttribution?: string;
	quoteAttribution?: { name: string; title: string };
	size?: 'extra-large' | 'large';
	tag: ElementType;
	text: string;
	textAlignment?: 'center' | 'left';
}

// Aspect ratio Map
export const AspectRatioMap: Record<string, `${number}:${number}`> = {
	'1-1': '1:1',
	'4-5': '4:5',
};

export const QuoteBlock: FC<BlockProps<QuoteBlockAttributes>> = ({ block }) => {
	if (!block) {
		return null;
	}
	const {
		attributes: {
			aspectRatio = '1-1',
			focalPoint,
			layout,
			media,
			mediaAttribution,
			quoteAttribution = { name: '', title: '' },
			size,
			text,
			textAlignment = 'left',
		},
	} = block;

	const classnames = clsx(
		styles.quote,
		styles[`is-${layout}`],
		styles[`is-aspect-ratio-${aspectRatio}`],
		`has-text-align-${textAlignment}`,
		{
			[styles[`is-${size}-size`]]: size,
		},
	);

	return (
		<div className={classnames}>
			<figure className={styles.quote__quoteContainer}>
				<blockquote className={clsx(styles.quote__quote, styles.quote__text)}>
					<p className={clsx(styles.quote__text)}>{text}</p>
				</blockquote>
				<figcaption className={styles.quote__quoteAttribution}>
					<p className={styles['quote__contributor-name']}>
						{quoteAttribution.name}
					</p>
					<p className={styles['quote__contributor-role']}>
						{quoteAttribution.title}
					</p>
				</figcaption>
			</figure>
			{layout !== 'text-only' && media && (
				<figure className={styles.quote__image}>
					<div className={styles.quote__imageWrapper}>
						<Image
							alt={media.alt}
							aspectRatio={AspectRatioMap[aspectRatio]}
							className={styles.quote__image}
							fill
							focalX={focalPoint?.x}
							focalY={focalPoint?.y}
							src={media.url}
						/>
					</div>
					{mediaAttribution && (
						<figcaption className={styles['quote__media-attributes']}>
							{mediaAttribution}
						</figcaption>
					)}
				</figure>
			)}
		</div>
	);
};
