import clsx from 'clsx';
import { Button } from '@/components/raven/ui/Button';
import type { PaginationLinkProps } from '@/types/pagination';

import styles from '../styles.module.css';

export const PaginationLink = ({
	getUrl,
	isCurrent,
	isFlanker,
	number,
}: PaginationLinkProps) => {
	return (
		<Button
			className={clsx(
				isFlanker ? styles.paginationFlanker : '',
				isCurrent ? styles.isCurrent : '',
			)}
			href={getUrl(number)}
			label={`Go to page ${number}`}
			variation={isCurrent ? 'primary-square' : 'primary-square-no-fill'}
		>
			{isCurrent ? (
				<span
					aria-current="page"
					className={styles.paginationText}
					data-testid="current-page"
				>
					{number}
				</span>
			) : (
				<span className={styles.paginationText}>{number}</span>
			)}
		</Button>
	);
};
