import type { FC } from 'react';
import { useState } from 'react';
import { useEventsByType } from '@/utils/hooks/use-events';
import { ARTICLE_FETCH, ARTICLE_FETCH_COMPLETE } from '@/utils/events';

import styles from './styles.module.css';

export const KeepReading: FC = () => {
	const [fetching, setFetching] = useState(false);
	useEventsByType(ARTICLE_FETCH, () => setFetching(true));
	useEventsByType(ARTICLE_FETCH_COMPLETE, () => setFetching(false));

	return (
		<div className={styles.keepReading}>
			{fetching ? (
				<span className={styles.loading}>Loading</span>
			) : (
				<span>Keep Reading</span>
			)}
		</div>
	);
};
