import clsx from 'clsx';

import styles from '../styles.module.css';

type EllipsesProps = {
	hideDesktop: boolean;
	testId?: string;
};

export const Ellipses = ({ hideDesktop, testId }: EllipsesProps) => (
	<div
		className={clsx(
			styles.paginationEllipsis,
			hideDesktop ? styles.hideDesktop : '',
		)}
		data-testid={testId}
	>
		<span aria-label="ellipses" role="img">
			...
		</span>
	</div>
);
