import clsx from 'clsx';
import type { FC } from 'react';
import type { EmbedBlockProps } from '@/types/embeds';
import { ScrollVisible } from '@/components/ScrollVisible';
import { EmbedGDPR } from '@/components/raven/Blocks/embed/EmbedGDPR';
import { InstagramEmbedWrapper } from '@/components/raven/Blocks/embed/InstagramEmbedBlock/InstagramEmbedWrapper';

import styles from './styles.module.css';

export const InstagramEmbedBlock: FC<EmbedBlockProps> = ({
	block,
	children,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the GDPR and cause a Next.js error
	...rest
}) => {
	const className = clsx(block?.className, styles.wrapper, 'fade-in-top');

	return (
		<EmbedGDPR
			purposes={['content']}
			url={block?.attributes.url}
			vendorId="instagram"
			{...rest}
		>
			<InstagramEmbedWrapper url={(block?.attributes.url || '') as string}>
				<ScrollVisible as="figure" className={className}>
					{children}
				</ScrollVisible>
			</InstagramEmbedWrapper>
		</EmbedGDPR>
	);
};
