import clsx from 'clsx';
import { slugify } from '@/utils/slugify';
import type { CtaProps } from '@/types/cta';
import { Cta } from '@/components/raven/Cta';
import type { FC, CSSProperties } from 'react';
import type { RavenIcon } from '@/utils/get-icons';
import { HtmlDecoder } from '@headstartwp/core/react';
import { ArticleTile } from '@/components/ArticleTile';
import { ScrollVisible } from '@/components/ScrollVisible';
import type {
	ArticleTileProps,
	ArticleTileVariation,
	ArticleGridVariation,
} from '@/types/articles';

import styles from './styles.module.css';

interface ArticleGridProps {
	articleTitleTag?: 'h2' | 'h3';
	className?: string;
	cta?: CtaProps;
	ctaIcon?: RavenIcon;
	ctaStyle?: 'link' | 'primary' | 'secondary';
	gridVariation?: ArticleGridVariation;
	posts: ArticleTileProps[];
	title?: string;
	titleTag?: 'h1' | 'h2';
	variation?: 'horizontal' | 'stacked';
}

export const ArticleGrid: FC<ArticleGridProps> = ({
	articleTitleTag,
	className,
	cta,
	ctaIcon,
	ctaStyle,
	gridVariation = 'standard',
	posts,
	title,
	titleTag,
	variation = 'stacked',
}) => {
	const cx = clsx(styles.articlesGrid, className, {
		'is-style-pairing-2': gridVariation === 'content-card',
		[styles.articleGridWall]: gridVariation === 'gridwall',
		[styles.articlesGridCollage]:
			variation === 'stacked' && gridVariation === 'posts-collage',
		[styles.articlesGridContentCard]:
			variation === 'horizontal' && gridVariation === 'content-card',
		[styles.articlesGridHorizontal]:
			variation === 'horizontal' && gridVariation === 'standard',
	});

	let tileVariation =
		gridVariation === 'content-card'
			? 'wide'
			: (variation as ArticleTileVariation);
	if (gridVariation === 'gridwall') {
		tileVariation = 'gridwall';
	}

	const TitleTag = titleTag ?? 'h2';
	let articleTileTitleTag = articleTitleTag ?? 'h3';

	if (!title) {
		articleTileTitleTag = 'h2';
	}

	return (
		<div className={cx}>
			{title && (
				<ScrollVisible
					as={TitleTag}
					className={clsx(styles.articlesGridTitle, 'fade-in-top')}
					id={slugify(`posts ${title}`)}
				>
					<HtmlDecoder html={title ?? ''} />
				</ScrollVisible>
			)}

			<ScrollVisible
				className={styles.articleGridContainer}
				tallThreshold={0}
				threshold={0.25}
			>
				{posts.map((post, index) => (
					<div
						className={styles.articleGridItem}
						key={post.id}
						style={{ '--index': index } as CSSProperties}
					>
						<ArticleTile
							{...post}
							category={
								gridVariation === 'content-card'
									? 'Related Story'
									: post.category
							}
							cta={gridVariation === 'content-card' ? cta : undefined}
							hideAuthor={gridVariation !== 'gridwall' || post.hideAuthor}
							hideCategory={
								(gridVariation !== 'content-card' &&
									gridVariation !== 'gridwall') ||
								post.hideCategory
							}
							hideDate={gridVariation !== 'gridwall' || post.hideDate}
							hideTags={gridVariation !== 'gridwall' || post.hideTags}
							tags={post.tags}
							titleTag={articleTileTitleTag}
							variation={tileVariation}
						/>
					</div>
				))}
			</ScrollVisible>

			{cta && gridVariation !== 'content-card' && (
				<Cta
					className={styles.articlesGridCta}
					cta={cta}
					icon={ctaIcon}
					variation={ctaStyle}
				/>
			)}
		</div>
	);
};
