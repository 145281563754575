import { stripTags } from '@headstartwp/core';
import { decodeEntities } from '@/utils/post-helpers';

/**
 * Decodes HTML entities and removes any HTML tags from the given text.
 *
 * @param {string | undefined} text - The input string that may contain HTML entities and tags.
 * @returns {string} The cleaned string with decoded entities and stripped tags.
 *
 * @example
 * decodeAndStripTags("&lt;p&gt;Hello &amp; welcome!&lt;/p&gt;");
 * // Returns: "Hello & welcome!"
 */
export const decodeAndStripTags = (text: string | undefined) => {
	if (!text) {
		return '';
	}

	return stripTags(decodeEntities(text));
};
