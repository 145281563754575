import type { AuthorTaxonomy, ContributorCredits } from '@/types/entities';

/**
 * The function `filterAuthorsWithoutCredits` filters out authors who do not have credits based on the
 * provided author taxonomy and contributor credits.
 * @param {AuthorTaxonomy[] | null} [authors] - The `authors` parameter is an array of `AuthorTaxonomy`
 * objects, which likely contain information about authors such as their name, ID, and other relevant
 * details.
 * @param {ContributorCredits} [credits] - The `credits` parameter is an object that contains
 * contributor credits, where the keys are author IDs and the values are the corresponding credits for
 * each author.
 * @returns An array of AuthorTaxonomy objects that do not have corresponding credits in the
 * ContributorCredits object.
 */
export const filterAuthorsWithoutCredits = (
	authors?: AuthorTaxonomy[] | null,
	credits?: ContributorCredits,
) => {
	if (!authors) {
		return [];
	}

	const filteredAuthors = authors.filter(
		(author) =>
			!(
				credits &&
				Object.prototype.hasOwnProperty.call(credits, author.id.toString())
			),
	);

	return filteredAuthors;
};
