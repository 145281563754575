import type { FC } from 'react';
import type { CardProps } from '@/types/card';
import { getAuthor } from '@/utils/get-author';
import type { Element } from 'html-react-parser';
import { safeJSONParse } from '@/utils/json-parse';
import type { ApiResponse } from '@/utils/data/types';
import { serializePostLookup } from '@/utils/post-helpers';
import type { FocalPoint, PostLookup } from '@/types/entities';
import type { PostEntity, AuthorEntity } from '@headstartwp/core';
import type { BlockProps, BlockContext } from '@headstartwp/core/react';
import {
	getImages,
	type SwipeGalleryImage,
	extractMediaCarouselCaptions,
} from '@/utils/image-utils';
import {
	MediaCarousel,
	type MediaCarouselProps,
} from '@/components/raven/content/Carousels/MediaCarousel';
import {
	getInfoblockInfo,
	type CommerceInfoblockPropsWithId,
} from '@/components/raven/Blocks/commerce/CommerceInfoblockBlock';

interface CustomPostEntity extends Omit<PostEntity, 'categories'>, FocalPoint {
	categories: {
		id: number;
		link: string;
		name: string;
		slug: string;
	}[];
	listing_featured_video?: {
		thumbnail_url: string;
		videoSrc: string;
	};
}

type CarouselVariation =
	| 'article-carousel'
	| 'commerce-carousel'
	| 'gallery-carousel';

const getCarouselImages = (domNode: Element, randomOrder: boolean) => {
	const galleryElement = (domNode as Element).children.filter(
		(node) => node.type === 'tag',
	);
	return getImages(galleryElement[0] as Element, randomOrder);
};

const getInfoblockInfos = (
	domNode: Element,
): CommerceInfoblockPropsWithId[] => {
	const elements = (domNode as Element).children.filter(
		(node) => node.type === 'tag',
	);

	return elements.map((element, idx) => {
		return {
			id: idx,
			...getInfoblockInfo(element as Element),
		};
	});
};

const getArticles = (domNode: Element, blockContext?: BlockContext) => {
	const postsElement = (domNode as Element).children.filter(
		(node) => node.type === 'tag',
	);
	const parsed = safeJSONParse(
		(postsElement[0] as Element).attribs['data-wp-block'],
	);
	const {
		aspectRatio,
		curationType,
		hideAuthor,
		hideCategory,
		hideDate,
		numberOfPosts,
		pagination,
		postLayout,
		postType,
		taxonomy,
		terms,
		variation,
	} = parsed;
	let { posts } = parsed;
	const postVariation =
		postLayout === 'split' || variation === 'content-card'
			? 'horizontal'
			: 'stacked';

	if (!posts || posts.length === 0) {
		if (curationType === 'automatic') {
			const key = serializePostLookup(
				{ numberOfPosts, pagination, postType, taxonomy, terms } as PostLookup,
				1, // always page 1
			);

			const uniqueBlocks = (blockContext?.uniqueBlocks || {}) as Record<
				string,
				ApiResponse<CustomPostEntity[]>
			>;

			if (blockContext && key in uniqueBlocks) {
				const data = uniqueBlocks[key];
				posts = data.json;
			}
		}
	}

	if (!posts || posts.length === 0) {
		return null;
	}

	const articles: CardProps[] = posts.map((post: CustomPostEntity) => {
		const category =
			post.categories.length > 0 && post.categories[0]?.name !== 'Uncategorized'
				? post.categories[0].name
				: '';
		return {
			aspectRatio,
			author: !hideAuthor ? getAuthor(post.authors as AuthorEntity[]) : null,
			category: !hideCategory ? category : null,
			date: !hideDate ? post.date : null,
			id: post.id,
			image: post.featured_media,
			link: post.link,
			mediaType: 'image',
			title: post.title,
			variation: 'stacked',
		};
	});

	return articles;
};

function getSlides(
	domNode: Element,
	variation: CarouselVariation,
	blockContext?: BlockContext,
):
	| CardProps[]
	| CommerceInfoblockPropsWithId[]
	| SwipeGalleryImage[]
	| undefined {
	switch (variation) {
		case 'article-carousel':
			return getArticles(domNode, blockContext) as CardProps[];
		case 'commerce-carousel':
			return getInfoblockInfos(domNode) as CommerceInfoblockPropsWithId[];
		case 'gallery-carousel':
			return getCarouselImages(domNode, false) as SwipeGalleryImage[];
		default:
			return undefined;
	}
}

export const MediaCarouselBlock: FC<BlockProps> = ({
	block,
	blockContext,
	children,
	domNode,
}) => {
	if (!domNode) {
		return null;
	}

	const { ctaLink, subtitle, title, variation } =
		block?.attributes as unknown as MediaCarouselProps;

	if (!variation) {
		return null;
	}

	const slides = getSlides(
		domNode,
		variation as CarouselVariation,
		blockContext,
	);

	const imageSrcToCaptionReactNodes = extractMediaCarouselCaptions(children);

	let defaultTitle = '';

	switch (variation) {
		case 'article-carousel':
			defaultTitle = 'Featured Articles';
			break;
		case 'commerce-carousel':
			defaultTitle = 'Featured Products';
			break;
		case 'gallery-carousel':
			defaultTitle = 'Featured Gallery';
			break;
		default:
			defaultTitle = title || '';
	}

	return (
		slides &&
		slides.length > 0 && (
			<MediaCarousel
				captions={imageSrcToCaptionReactNodes}
				ctaLink={ctaLink}
				slides={slides}
				subtitle={subtitle}
				title={title || defaultTitle}
				variation={variation}
			/>
		)
	);
};
