import type { Post } from '@/types/entities';

// Utility function to determine if a post contains video embeds or
// as featured media.
export const hasVideo = (post: Post): boolean => {
	return !!(
		!!post.meta.featured_video?.embed_url ||
		post.content.rendered?.includes('wp-block-jw-player-video') ||
		post.content.rendered?.includes('wp-block-embed-youtube')
	);
};
