import clsx from 'clsx';
import { getImage } from '@/utils/image-utils';
import { ImageCaption } from '@/components/ImageCaption';
import { type FC, Children, isValidElement } from 'react';
import { ScrollVisible } from '@/components/ScrollVisible';
import { ImageLinkWrapper } from '@/components/ImageLinkWrapper';
import { renderCommerceLink } from '@/utils/commerce/commerceUtils';
import { Image, type CloudinaryImageProps } from '@/components/raven/Image';
import type {
	BlockProps,
	ImageBlockProps as ImageBlockCoreProps,
} from '@headstartwp/core/react';
import {
	isCommerceOffer,
	type LinkEmbedConfig,
	isCompleteCommerceItem,
} from '@themaven-net/commerce-shared';

import styles from './styles.module.css';

export interface ImageBlockAttributes
	extends Pick<ImageBlockCoreProps, 'alt' | 'linkDestination' | 'sizeSlug'>,
		Pick<CloudinaryImageProps, 'aspectRatio'> {
	className?: string;
	commerceConfig?: LinkEmbedConfig;
	focalPoint?: {
		x: number;
		y: number;
	};
	hasCommerceLink: boolean;
	height?: string;
	id: number;
	lightbox?: {
		enabled: boolean;
	};
	width?: string;
}

export const ImageBlock: FC<
	BlockProps<{
		sizeSlug?: string;
		sourceName?: string;
		sourceUrl?: string;
		styleConfig?: {
			shadow?: string;
		};
	}>
> = ({ block, children, domNode }) => {
	if (!block || !domNode) {
		return null;
	}

	const {
		alt,
		commerceConfig,
		hasCommerceLink,
		href,
		id,
		linkClass,
		linkTo,
		rel,
		target,
		...image
	} = getImage(domNode);
	const isFullSizeImage = block.className.includes('alignfull');
	const imageSizes = isFullSizeImage
		? '100vw'
		: '(min-width: 750px) 750px, calc(100vw - 60px)';
	const childrenArray = Children.toArray(children);
	const imageCaption = childrenArray.slice(1);
	let imageCaptionText;

	if (imageCaption.length >= 1) {
		const firstChild = imageCaption[0];

		if (isValidElement(firstChild)) {
			imageCaptionText = firstChild.props?.children;
		}
	}

	const hasCaption =
		Boolean(imageCaptionText) || Boolean(block.attributes.sourceName);
	let className = '';

	if (block.attributes?.styleConfig?.shadow) {
		className += block.attributes.styleConfig.shadow.replace(
			'var:preset|shadow|',
			'is-',
		);
	}

	return (
		<ScrollVisible
			as="figure"
			className={clsx(styles.figureContainer, block.className, 'fade-in-top')}
		>
			{hasCommerceLink ? (
				renderCommerceLink(
					<Image {...image} alt={alt} sizes={imageSizes} />,
					isCommerceOffer(commerceConfig?.item?.offers?.[0])
						? commerceConfig?.item?.offers?.[0]
						: '',
					isCompleteCommerceItem(commerceConfig?.item)
						? commerceConfig?.item
						: undefined,
					linkClass,
					'image-link',
					commerceConfig,
					href,
				)
			) : (
				<ImageLinkWrapper
					link={href}
					linkClass={linkClass}
					linkTarget={target}
					linkTo={linkTo}
					rel={rel}
				>
					<Image
						{...image}
						alt={alt}
						className={className}
						sizes={imageSizes}
					/>
				</ImageLinkWrapper>
			)}
			{hasCaption && (
				<ImageCaption
					sourceName={block.attributes.sourceName}
					sourceUrl={block.attributes.sourceUrl}
				>
					{imageCaptionText}
				</ImageCaption>
			)}
		</ScrollVisible>
	);
};
