import type { FC } from 'react';
import styles from '@/components/raven/content/Recipe/styles.module.css';
import type {
	NutritionFactsMap,
	NutritionFactsProps as NutritionFactsType,
} from '@/types/recipe';

interface NutritionFactsProps {
	legacyNutritionFacts?: string;
	nutritionFacts?: NutritionFactsType;
}

export const nutritionFactToReadableTextMap: Record<NutritionFactsMap, string> =
	{
		calories: 'Calories',
		carbohydrateContent: 'Carbs',
		cholesterolContent: 'Cholesterol',
		fatContent: 'Fat',
		fiberContent: 'Fiber',
		monounsaturatedFatContent: 'Monounsaturated Fat',
		polyunsaturatedFatContent: 'Polyunsaturated Fat',
		proteinContent: 'Protein',
		saturatedFatContent: 'Saturated Fat',
		sodiumContent: 'Sodium',
		sugarContent: 'Sugar',
		transFatContent: 'Trans Fat',
		unsaturatedFatContent: 'Unsaturated Fat',
	};

export const nutritionFactToUnitMap: Record<NutritionFactsMap, string> = {
	calories: 'kcal',
	carbohydrateContent: 'g',
	cholesterolContent: 'mg',
	fatContent: 'g',
	fiberContent: 'g',
	monounsaturatedFatContent: 'g',
	polyunsaturatedFatContent: 'g',
	proteinContent: 'g',
	saturatedFatContent: 'g',
	sodiumContent: 'mg',
	sugarContent: 'g',
	transFatContent: 'g',
	unsaturatedFatContent: 'g',
};

export const NutritionFacts: FC<NutritionFactsProps> = ({
	legacyNutritionFacts,
	nutritionFacts,
}) => {
	if (!nutritionFacts && !legacyNutritionFacts) {
		return null;
	}

	const hasValidNutritionFacts =
		nutritionFacts &&
		Object.values(nutritionFacts).some(
			(value) => value !== '0' && value !== '',
		);

	if (!hasValidNutritionFacts && !legacyNutritionFacts) {
		return null;
	}

	return (
		<section aria-label="Nutrition Facts" className={styles.recipeSection}>
			<div className={styles.recipeNutritionFactsHead}>
				<h2 className={styles.recipeSectionTitle}>Nutrition Facts</h2>
				<span className={styles.recipePerServing}>(per serving)</span>
			</div>

			{/* This is used for legacy content */}
			{legacyNutritionFacts && !hasValidNutritionFacts && (
				<p>{legacyNutritionFacts}</p>
			)}

			{hasValidNutritionFacts && (
				<dl className={styles.recipeNutritionFactsList}>
					{Object.entries(nutritionFacts).map(([key, value]) => {
						const label =
							nutritionFactToReadableTextMap[key as NutritionFactsMap];
						const unit = nutritionFactToUnitMap[key as NutritionFactsMap];

						if (!value || value === '0') {
							return null;
						}

						return (
							<div className={styles.recipeNutritionFactsItem} key={key}>
								<dt className={styles.recipeNutritionFactsLabel}>{label}</dt>
								<hr className={styles.recipeNutritionFactsDivider} />
								<dd className={styles.recipeNutritionFactsValue}>
									{value}
									{unit}
								</dd>
							</div>
						);
					})}
				</dl>
			)}
		</section>
	);
};
