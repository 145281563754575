import clsx from 'clsx';
import type { FC } from 'react';
import { safeJSONParse } from '@/utils/json-parse';
import type { BlockProps } from '@headstartwp/core/react';
import { CommerceLink } from '@/components/Commerce/CommerceLink';
import { CommerceItemContextProvider } from '@/context/CommerceItemContext';
import { isCommerceLinkRequestStatus } from '@/utils/commerce/commerceUtils';

export const CommerceLinkBlock: FC<BlockProps> = ({ children, domNode }) => {
	if (!domNode) {
		return null;
	}
	const enableTrackonomics = domNode.attribs['data-trackonomics'] === 'true';
	const enableSkimlinks = domNode.attribs['data-skimlinks'] === 'true';
	const href = domNode.attribs.href || undefined;
	const commerceData = safeJSONParse(domNode.attribs['data-commerce']);
	const rel = domNode.attribs.rel || undefined;
	const className = clsx(domNode.attribs.class, {
		noskim: !enableSkimlinks,
	});
	// Check if we don't actually have a result yet
	if (isCommerceLinkRequestStatus(commerceData?.item)) {
		return null;
	}
	return (
		<CommerceItemContextProvider commerceItem={commerceData?.item}>
			<CommerceLink
				className={className}
				commerceConfig={commerceData}
				disableSkimlinks={!enableSkimlinks}
				disableTrackonomics={!enableTrackonomics}
				href={href}
				linkTarget={domNode.attribs?.target ?? '_blank'}
				rel={rel}
			>
				{children}
			</CommerceLink>
		</CommerceItemContextProvider>
	);
};
