import type { FC } from 'react';
import type { DOMNode } from 'html-react-parser';
import type { BlockProps } from '@headstartwp/core/react';
import { ScrollVisible } from '@/components/ScrollVisible';
import { isElement, isBlockByName } from '@headstartwp/core';

import { SecureHTMLScript } from './SecureHTMLScript';

export const HtmlScriptBlock: FC<BlockProps> = ({ children }) => (
	<ScrollVisible>
		<SecureHTMLScript content={`${children}`} />
	</ScrollVisible>
);

export const isHtmlScriptBlock = (node: DOMNode) => {
	if (!isElement(node)) {
		return false;
	}

	const isCoreHTML = isBlockByName(node, 'core/html');
	const isScript = node.name === 'script';

	return isCoreHTML && isScript;
};
