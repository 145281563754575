import clsx from 'clsx';
import type { FC } from 'react';
import { Image } from '@/components/raven/Image';
import User from '@themaven-net/raven-assets/User';

import styles from './styles.module.css';

export interface AvatarProps {
	className?: string;
	priority?: boolean;
	src?: string;
	variant?: 'large' | 'medium' | 'small' | 'xsmall';
}

const variantWidth = {
	large: 200,
	medium: 100,
	small: 32,
	xsmall: 25,
};

export const Avatar: FC<AvatarProps> = ({
	className,
	priority = false,
	src,
	variant = 'medium',
}) => (
	<div
		className={clsx(styles.avatar, className, {
			[styles.large]: variant === 'large',
			[styles.medium]: variant === 'medium',
			[styles.small]: variant === 'small',
			[styles.xsmall]: variant === 'xsmall',
		})}
	>
		{src ? (
			<Image
				alt=""
				aspectRatio="1:1"
				className={styles.avatarImage}
				fill
				priority={priority}
				sizes={`${variantWidth[variant]}px`}
				src={src}
			/>
		) : (
			<User className={styles.avatarIcon} />
		)}
	</div>
);
