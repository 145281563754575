import type { FC } from 'react';
import type { RecipePostMeta } from '@/types/entities';
import type { BlockProps } from '@headstartwp/core/react';
import { Recipe } from '@/components/raven/content/Recipe';
import { generateRecipeFromBlock } from '@/utils/recipe/generate-recipe-from-block';

interface RecipeBlockProps extends BlockProps {
	recipeDetails?: RecipePostMeta;
}

export const RecipeBlock: FC<RecipeBlockProps> = ({
	domNode,
	recipeDetails,
}) => {
	if (!domNode) {
		return null;
	}

	const recipe = generateRecipeFromBlock(domNode);

	if (!recipe) {
		return null;
	}

	return <Recipe {...{ ...recipe, ...recipeDetails }} />;
};
