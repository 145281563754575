import clsx from 'clsx';
import type { FC } from 'react';
import { safeJSONParse } from '@/utils/json-parse';
import type { BlockProps } from '@headstartwp/core/react';
import { CommerceCta } from '@/components/raven/Commerce/CommerceCTA';
import { CommerceItemContextProvider } from '@/context/CommerceItemContext';

export const CommerceCtaBlock: FC<BlockProps> = ({ domNode }) => {
	if (!domNode) {
		return null;
	}
	const { commerceConfig, href, skimlinks, text, trackonomics } = safeJSONParse(
		domNode.attribs['data-wp-block'],
	);
	const className = clsx(domNode.attribs.class, 'button', 'button--primary', {
		noskim: !skimlinks,
	});
	return (
		<CommerceItemContextProvider>
			<CommerceCta
				buttonTextOverride={text}
				className={className}
				config={commerceConfig}
				disableSkimlinks={!skimlinks}
				disableTrackonomics={!trackonomics}
				href={href}
			/>
		</CommerceItemContextProvider>
	);
};
