import type { FC } from 'react';
import type { Element } from 'html-react-parser';
import type { BlockProps } from '@headstartwp/core/react';
import { NewsletterBlockWrapper } from '@/components/raven/Blocks/NewsletterBlock/NewsletterBlockWrapper';

export const NewsletterBlock: FC<BlockProps> = ({ domNode }) => {
	if (!domNode || !domNode.attribs) {
		return null;
	}

	return (
		<div className={domNode.attribs.class ?? ''}>
			<NewsletterBlockWrapper />
		</div>
	);
};

export const isNewsletter = (node: Element) => {
	return (
		node.name === 'div' && node.attribs?.['data-smart-slot'] === 'newsletters'
	);
};
