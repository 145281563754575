'use client';

import type { FC } from 'react';
import Script from 'next/script';
import { GDPR } from '@/components/GDPR';
import { useSiteContext } from '@/context/SiteContext';

export const PinIt: FC = () => {
	const { config: siteConfig } = useSiteContext();
	const enabled = siteConfig?.social?.pinItImages ?? false;

	if (!enabled) {
		return null;
	}

	return (
		<GDPR purposes={['content']} vendorId="pinterest">
			<Script
				data-pin-hover="true"
				src="https://assets.pinterest.com/js/pinit.js"
			/>
		</GDPR>
	);
};
