import type { FC } from 'react';
import type { BlockProps } from '@headstartwp/core/react';
import {
	TableOfContents,
	type TableOfContentsProps,
} from '@/components/raven/content/TableOfContents';

export const TableOfContentsBlock: FC<BlockProps> = ({ block }) => {
	const headings = block?.attributes
		.toc as unknown as TableOfContentsProps['headings'];
	const { includeBackground, includeNumbers, title } = block?.attributes ?? {};

	return (
		<TableOfContents
			headings={headings}
			includeBackground={
				includeBackground as unknown as TableOfContentsProps['includeBackground']
			}
			includeNumbers={
				includeNumbers as unknown as TableOfContentsProps['includeNumbers']
			}
			title={title as unknown as TableOfContentsProps['title']}
		/>
	);
};
