import type { FC } from 'react';
import type { RecipeGroupProps } from '@/types/recipe';
import styles from '@/components/raven/content/Recipe/styles.module.css';
import { RecipeList } from '@/components/raven/content/Recipe/RecipeList';

export const RecipeGroup: FC<RecipeGroupProps> = ({ items, title }) => {
	if (!items.length && !title) {
		return null;
	}

	return (
		<div className={styles.recipeGroup}>
			{title && <h4 className={styles.recipeGroupTitle}>{title}</h4>}

			<RecipeList items={items} />
		</div>
	);
};
