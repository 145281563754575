import type { FC } from 'react';
import type { FocalPoint } from '@/types/entities';
import type { PostEntity } from '@headstartwp/core';
import type { ArticleTileProps } from '@/types/articles';
import type { BlockProps } from '@headstartwp/core/react';
import {
	CarouselCategory,
	type CarouselCategoryProps,
} from '@/components/raven/content/Carousels/CarouselCategory';

interface CustomPostEntity extends Omit<PostEntity, 'categories'>, FocalPoint {}

export const CarouselCategoryBlock: FC<BlockProps> = ({ block, domNode }) => {
	if (!domNode) {
		return null;
	}

	const { addStroke, className, terms, title } =
		block?.attributes as unknown as CarouselCategoryProps;

	if (!terms || !Array.isArray(terms) || terms.length === 0) {
		return null;
	}

	const slides = terms.map((term: CustomPostEntity) => ({
		aspectRatio: '1-1',
		hideAuthor: true,
		hideCategory: true,
		hideDate: true,
		id: term.id,
		image: term.featured_image
			? {
					focalX: term.focal_point?.x,
					focalY: term.focal_point?.y,
					src: term.featured_image,
				}
			: null,
		link: term.link,
		title: term.title,
		titleTag: 'h2',
		variation: 'category',
	}));

	return (
		<CarouselCategory
			addStroke={addStroke}
			className={className}
			slides={slides as unknown as ArticleTileProps[]}
			terms={terms}
			title={title}
		/>
	);
};
