'use client';

import { useState, useEffect } from 'react';
import { useDebouncedCallback } from '@/utils/hooks/use-debounced-callback';

enum TaigaValidationFailureReason {
	NotAvailable = 'That username is not available',
	TooShort = 'Username too short',
	TooLong = 'Username too long',
	ProhibitedCharacter = "Username can contain only letters, numbers, and the characters -_.$*'",
	UserHasExistingDisplayName = 'You already have a username',
}

export const useValidateUsername = (username: null | string) => {
	const [usernameError, setUsernameError] = useState<string | undefined>(
		undefined,
	);

	const validateDebounced = useDebouncedCallback(
		async () => {
			const validResp = await fetch('/.api/user/validate/display-name', {
				body: JSON.stringify({ displayName: username }),
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'post',
			}).then((r) => r.json());
			if (validResp.isValid) {
				setUsernameError('');
			} else {
				/** Provides the reason for an invalid username */
				const { reason } = validResp as {
					reason: keyof typeof TaigaValidationFailureReason;
				};
				const errorMessage = TaigaValidationFailureReason[reason];
				setUsernameError(errorMessage);
			}
		},
		[username],
		600,
	);

	useEffect(() => {
		if (username) {
			validateDebounced();
		}
	}, [username, validateDebounced]);
	/** returns the reason for an invalid username */
	return usernameError;
};
