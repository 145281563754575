'use client';

import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import { SingleArticle } from '@/components/SingleArticle';
import { ArticleListProvider } from '@/context/ArticleList';
import { useRef, type FC, useState, useEffect } from 'react';
import { useAppSettings } from '@/context/AppSettingsContext';
import { generatePageClass } from '@/utils/generate-page-class';
import { fetchContinuousScroll } from '@/components/LiftIgniter';
import { useHeaderHeight } from '@/utils/hooks/use-header-height';
import { ScrollSentinel } from '@/components/global/ScrollSentinel';
import { DemonetizationWatcher } from '@/components/DemonetizationWatcher';
import { ContextualNavigation } from '@/components/raven/ContextualNavigation';
import { events$, ARTICLE_FETCH, ARTICLE_FETCH_COMPLETE } from '@/utils/events';

import type { Article } from './types';

const fetchNextPost = (ids: Array<number | string>): Promise<Article | null> =>
	fetch(`/.api/get-post-by-id?id=${ids[0]}`)
		.then((r) => (r.ok ? r.json() : Promise.reject()))
		.catch(() => (ids.length > 1 ? fetchNextPost(ids.slice(1)) : null));

export const ArticleList: FC<{ post: Article }> = ({ post: initialPost }) => {
	const adService = useAdService();
	const appSettings = useAppSettings();
	const { config } = useSiteContext();
	const { settings } = appSettings;
	const timeZone = appSettings.settings.timezone;
	const affiliateDisclaimer =
		appSettings.settings.commerce?.affiliate_disclaimer;
	const [posts, setPosts] = useState([initialPost]);
	const [loadDistance, setLoadDistance] = useState(200);
	const [isSticky, setSticky] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const lastArticleRef = useRef(null);
	const seen = useRef(new Set<number>([initialPost.id]));
	const pageClass = generatePageClass(initialPost);
	const headerHeight = useHeaderHeight();

	useEffect(() => {
		if (lastArticleRef.current) {
			setLoadDistance(
				Math.floor((lastArticleRef.current as Element).clientHeight * 0.2),
			);
		}
	}, []);

	useEffect(() => {
		// Enable ads when the component is unmounted
		return () => {
			if (adService) {
				adService.enableAds();
			}
		};
	}, [adService]);

	const idsToFetch: number[] = (posts.at(-1)?.next_page_id || []).filter(
		(id) => !seen.current.has(id),
	);

	const useLI = config.enabledFeatures['li-continuous-scroll'];
	const liFetchPromise = useRef<null | ReturnType<
		typeof fetchContinuousScroll
	>>(null);

	const onTailEnter = () => {
		const lastPost = posts.at(-1);
		const idsPromise = useLI
			? (liFetchPromise.current ??= fetchContinuousScroll(config.siteKeyword))
			: Promise.resolve(idsToFetch);
		idsPromise.then((unfilteredIds) => {
			const ids = unfilteredIds.filter(
				(candidateId) => !seen.current.has(candidateId),
			);
			if (ids.length > 0 && posts.length <= 10 && !isLoading) {
				setIsLoading(true);
				events$.next({
					type: ARTICLE_FETCH,
					value: { articleIds: ids },
				});
				fetchNextPost(ids)
					.then((article) => {
						setIsLoading(false);
						if (article) {
							setPosts((previousArticles: Article[]) => [
								...previousArticles,
								article,
							]);
							seen.current.add(article?.id);
							events$.next({
								type: ARTICLE_FETCH_COMPLETE,
								value: { article, lastArticle: lastPost },
							});
						}
					})
					.catch((e) => {
						setIsLoading(false);
						events$.next({
							type: ARTICLE_FETCH_COMPLETE,
							value: { error: e },
						});
					});
			}
		});
	};

	return (
		<main className={pageClass} id="main">
			<ContextualNavigation
				isSticky={isSticky}
				post={posts[posts.length - 1] ?? initialPost}
				settings={settings}
				useLI={useLI}
			/>
			<ScrollSentinel
				headRootMargin={`-${headerHeight}px 0px`}
				onHeadEnter={() => setSticky(false)}
				onHeadLeave={() => setSticky(true)}
				onTailEnter={onTailEnter}
				tailRootMargin={`${loadDistance}px 0px`}
			>
				<ArticleListProvider articles={posts}>
					{posts.map((post, index) => (
						<div key={`article-${post.id}`}>
							<SingleArticle
								disclaimer={affiliateDisclaimer || ''}
								index={index}
								isFirst={index === 0}
								isLast={
									index === posts.length - 1 &&
									(useLI
										? liFetchPromise.current?.ids?.length === posts.length - 1
										: idsToFetch.length === 0)
								}
								post={post}
								ref={index === posts.length - 1 ? lastArticleRef : null}
								timeZone={timeZone}
							/>
							<DemonetizationWatcher post={post} />
						</div>
					))}
				</ArticleListProvider>
			</ScrollSentinel>
		</main>
	);
};
