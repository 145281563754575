/* eslint-disable @next/next/no-sync-scripts */
import type { FC } from 'react';
import type { EmbedBlockProps } from '@/types/embeds';
import { JotformIframe } from '@/components/raven/Blocks/embed/JotformEmbedBlock/JotformIframe';

interface JotformEmbedBlockProps {
	url: string;
}

export const JotformEmbedBlock: FC<EmbedBlockProps> = ({
	block,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the GDPR and cause a Next.js error
}) => {
	if (!block) {
		return null;
	}

	const { url } = block.attributes as unknown as JotformEmbedBlockProps;

	if (!url) {
		return null;
	}

	const match = url.match(/\/(\d+)$/);

	if (!match) {
		return null;
	}

	const formid = match[1];

	return (
		<figure
			className="wp-block-embed is-type-rich is-provider-jotform wp-block-embed-jotform"
			data-wp-block='{"url":"https:\/\/form.jotform.com\/form\/250446937796069","type":"rich","providerNameSlug":"jotform","allowResponsive":true,"responsive":false,"previewable":true}'
			data-wp-block-name="core/embed"
		>
			<div className="wp-block-embed__wrapper">
				<JotformIframe formID={formid} url={url} />
			</div>
		</figure>
	);
};
