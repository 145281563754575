import clsx from 'clsx';
import type { FC } from 'react';
import type { WPImage } from '@/types/entities';
import { Image } from '@/components/raven/Image';
import { ImageCaption } from '@/components/ImageCaption';
import { ScrollVisible } from '@/components/ScrollVisible';
import { ImageLinkWrapper } from '@/components/ImageLinkWrapper';

import styles from './styles.module.css';

interface GalleryImageProps extends WPImage {
	imageCrop: boolean;
	index: number;
	linkTarget?: string;
	linkTo?: string;
	sizes: string;
}

export const GalleryImage: FC<GalleryImageProps> = ({
	alt_text,
	caption,
	imageCrop,
	index,
	link,
	linkTarget,
	linkTo,
	media_details,
	sizes,
	source_url,
}) => {
	if (!source_url) {
		return null;
	}

	const { height, width } = media_details;

	const cx = clsx(styles.galleryImageWrapper, 'fade-in-top', {
		[styles.galleryImageWrapperCropped]: imageCrop,
		[styles.galleryImageWrapperFill]: !height && !width,
	});

	return (
		<ScrollVisible
			as="figure"
			className={cx}
			key={source_url}
			style={{
				'--index': index,
			}}
		>
			<ImageLinkWrapper link={link} linkTarget={linkTarget} linkTo={linkTo}>
				<Image
					alt={alt_text ?? ''}
					className={styles.galleryImage}
					height={!imageCrop ? Number(height ?? 0) : 0}
					sizes={sizes}
					src={source_url}
					width={!imageCrop ? Number(width ?? 0) : 0}
				/>
			</ImageLinkWrapper>
			{caption.rendered && <ImageCaption caption={caption.rendered} />}
		</ScrollVisible>
	);
};
