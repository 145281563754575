import type { FC, JSX } from 'react';
import { InternalLink } from '@/components/InternalLink';
import { decodeAndStripTags } from '@/utils/decode-and-strip-tags';
import type { ByLineProps } from '@/components/raven/content/Byline';
import styles from '@/components/raven/content/Byline/styles.module.css';

export const ContributorsList: FC<Pick<ByLineProps, 'contributors'>> = ({
	contributors,
}) => {
	if (!contributors || contributors.length === 0) {
		return null;
	}

	return (
		<>
			{contributors
				.map(({ link, name }) => {
					const safeAuthorName = decodeAndStripTags(name);

					return (
						<InternalLink
							aria-label={`Read more articles from ${safeAuthorName}`}
							className={styles.contributorName}
							href={link}
							key={safeAuthorName}
						>
							{safeAuthorName}
						</InternalLink>
					);
				})
				.reduce<(JSX.Element | string)[]>(
					(acc, currentAuthor, index, authorsArray) => {
						if (index === authorsArray.length - 1) {
							return [
								...acc,
								<span className={styles.isContributorSecondToLast} key="and">
									{'and '}
								</span>,
								currentAuthor,
							];
						}

						return [...acc, currentAuthor, ', '];
					},
					[],
				)}
		</>
	);
};
