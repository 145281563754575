import clsx from 'clsx';
import type { FC, CSSProperties } from 'react';
import { getImages } from '@/utils/image-utils';
import { GalleryImage } from '@/components/GalleryImage';
import type { BlockProps } from '@headstartwp/core/react';

import styles from './styles.module.css';
import { SwipeGallery } from '../../SwipeGallery';

const getSizes = (columns: number) => {
	return [
		`(min-width: 768px) calc((100vw/${columns} - (20px * ${columns - 1}))`,
		'100vw)',
	].join(', ');
};

interface GalleryBlockAttributes {
	columns: number;
	fixedHeight: number;
	imageCrop: boolean;
	linkTo: string;
	randomOrder: boolean;
	sizeSlug: string;
	variation?: string;
}

export const GalleryBlock: FC<BlockProps> = ({ block, children, domNode }) => {
	if (!domNode) {
		return null;
	}

	const {
		columns = 3,
		imageCrop,
		randomOrder,
		variation,
	} = block?.attributes as unknown as GalleryBlockAttributes;

	const cx = clsx(styles.gallery, {
		[styles.galleryCropped]: imageCrop,
	});

	const images = getImages(domNode, randomOrder);
	const numColumns = variation === 'inline' ? 1 : columns;

	return variation === 'slideshow' || variation === 'content' ? (
		<SwipeGallery images={images}>{children}</SwipeGallery>
	) : (
		<div className={cx}>
			<div
				className={styles.galleryContainer}
				style={{ '--num-columns': numColumns } as CSSProperties}
			>
				{images.map((image, index) => (
					<div className={styles.galleryItem} key={image.id}>
						<GalleryImage
							{...image}
							imageCrop={imageCrop}
							index={index}
							sizes={getSizes(columns)}
						/>
					</div>
				))}
			</div>
		</div>
	);
};
