'use client';

import { getUserId } from '@/utils/cookies';
import { useSiteContext } from '@/context/SiteContext';
import { useAppSettings } from '@/context/AppSettingsContext';
import { useCommentsContext } from '@/context/CommentsContext';
import { updateUsername, getCommentsProfile } from '@/utils/sso';
import { useValidateUsername } from '@/utils/hooks/use-validate-username';
import {
	LoginForm,
	CreateUsername,
	type LoginFormProps,
} from '@/components/raven/global/LoginForm';
import {
	OAuthPhase,
	EmailLinkPhase,
	useLoginContext,
	type OAuthProviderType,
} from '@/context/LoginContext';
import {
	type FC,
	useMemo,
	useState,
	useEffect,
	useCallback,
	type ReactNode,
	type ChangeEvent,
} from 'react';
import {
	logout,
	ssoCallback,
	SPOTIM_EVENT,
	type UserData,
	openWebStartSSO,
	type OpenWebEvent,
} from '@/components/raven/Comments/OpenWeb';

export interface CommentsLoginProps {
	children?: ReactNode;
}

export const CommentsLogin: FC<CommentsLoginProps> = ({ children }) => {
	const appSettings = useAppSettings();
	const {
		config: { siteId, siteKeyword, siteName },
	} = useSiteContext();
	const {
		errorMessage,
		openWebEventsRef,
		setErrorMessage,
		setShowLogin,
		setStatus,
		showLogin,
		status,
	} = useCommentsContext();
	const {
		afterCompleteLoginFnRef,
		emailLinkPhase,
		handleAfterFirebaseLogin,
		handleSendEmailLink,
		isLoading,
		message,
		oauthPhase,
		rgisCookie,
		signInOAuth,
	} = useLoginContext();
	const [displayName, setDisplayName] = useState<boolean | string>(true);
	const [email, setEmail] = useState('');
	const userId = getUserId(rgisCookie);
	const loggedIn = Boolean(rgisCookie && userId);
	const [usernameErrorMessage, setUsernameErrorMessage] = useState<
		string | undefined
	>(undefined);
	const [username, setUsername] = useState<null | string>(null);

	const handleOAuthSignIn = useCallback(
		(social: OAuthProviderType) => {
			setStatus('idle');
			signInOAuth(social);
		},
		[setStatus, signInOAuth],
	);

	const onContinue = useCallback(async () => {
		const loginUrl = window.location.href;
		setStatus('loading');
		await handleSendEmailLink(email, loginUrl);
	}, [email, handleSendEmailLink, setStatus]);
	const onEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	}, []);

	const startTTH = useCallback(async () => {
		try {
			setStatus('loading');
			if (rgisCookie) {
				await openWebStartSSO(userId, ssoCallback(rgisCookie));
				setStatus('success');
				setShowLogin(false);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('OW SSO error', error);
			setStatus('idle');
		}
	}, [rgisCookie, setShowLogin, setStatus, userId]);

	/** Validates the entered username, sets an error if invalid */
	const tempUsernameError = useValidateUsername(username);
	if (tempUsernameError !== usernameErrorMessage) {
		setUsernameErrorMessage(tempUsernameError);
	}

	useEffect(() => {
		switch (oauthPhase) {
			case OAuthPhase.CHOOSE:
				setStatus('idle');
				break;
			case OAuthPhase.SIGNING_IN:
				setStatus('loading');
				break;
			default:
		}
		switch (emailLinkPhase) {
			case EmailLinkPhase.COMPLETED:
				setStatus('success');
				setShowLogin(false);
				break;
			case EmailLinkPhase.CONFIRM:
			case EmailLinkPhase.SENT:
				setStatus('success');
				break;
			case EmailLinkPhase.INPUT:
				setStatus('idle');
				break;
			case EmailLinkPhase.SIGNING_IN:
				setStatus('loading');
				break;
			default:
		}
	}, [emailLinkPhase, oauthPhase, setShowLogin, setStatus]);

	useEffect(() => {
		if (new URLSearchParams(window.location.search).get('apiKey')) {
			handleAfterFirebaseLogin();
		}
	}, [handleAfterFirebaseLogin, setStatus]);

	useEffect(() => {
		afterCompleteLoginFnRef.current = () => {
			const targetUrl = window.location.href;
			window.history.replaceState(
				{},
				document.title,
				targetUrl.replace(window.location.search, ''),
			);
		};
	}, [afterCompleteLoginFnRef]);

	useEffect(() => {
		const fetchData = async () => {
			if (!rgisCookie) {
				return;
			}
			const user = await getCommentsProfile(rgisCookie, siteKeyword);
			setDisplayName(user.displayName ?? false);
			if (user.displayName) {
				startTTH();
			}
		};
		fetchData();
	}, [rgisCookie, siteKeyword, startTTH]);

	useEffect(() => {
		openWebEventsRef.current = (e: OpenWebEvent) => {
			switch (e.detail.type) {
				case SPOTIM_EVENT.authSuccess:
					if ((e.detail.payload as UserData).isRegistered) {
						if (!loggedIn) {
							logout();
							break;
						}
						setShowLogin(false);
					}
					break;
				case SPOTIM_EVENT.loginStart:
					setShowLogin(true);
					if (loggedIn && displayName) {
						startTTH();
						break;
					}
					setShowLogin(true);
					break;
				case SPOTIM_EVENT.renewSSO:
					if (loggedIn && displayName) {
						startTTH();
					}
					break;
				case SPOTIM_EVENT.ssoError:
					setErrorMessage('Error trying to sign in to Open Web');
					setStatus('error');
					break;
				default:
			}
		};
	}, [
		displayName,
		loggedIn,
		openWebEventsRef,
		setErrorMessage,
		setShowLogin,
		setStatus,
		startTTH,
	]);

	const loginFormProps: LoginFormProps = useMemo(
		() => ({
			buttonText: 'Continue with Email',
			checkboxText: (
				<>
					By signing up I agree to{' '}
					<a href="/" rel="noopener noreferrer" target="_blank">
						{siteName}
					</a>
					, you agree to{' '}
					<a href="/terms" rel="noopener noreferrer" target="_blank">
						Terms of Use
					</a>
					. I acknowledge that I have read the{' '}
					<a href="/privacy" rel="noopener noreferrer" target="_blank">
						Privacy Policy
					</a>
					. You may unsubscribe from email communication at anytime.
				</>
			),
			descriptionDefault: message || 'Join the conversation',
			descriptionError:
				errorMessage || 'Please try again or use a different sign-in method.',
			descriptionLoading: 'Please wait while we sign you in.',
			descriptionSuccess:
				emailLinkPhase === EmailLinkPhase.SENT
					? `Check your email ${email} to complete the sign-in process. If it's missing, check spam or request a new one.`
					: message,
			handleOAuthSignIn,
			onContinue,
			onEmailChange,
			settings: appSettings.settings,
			status: isLoading ? 'loading' : status,
			textFieldPlaceholder: 'Enter your email',
			titleDefault: 'Sign-in to continue',
			titleError: 'Sign-in failed',
			titleLoading: 'Signing in...',
			titleSuccess: "You're almost there!",
		}),
		[
			appSettings.settings,
			email,
			emailLinkPhase,
			errorMessage,
			handleOAuthSignIn,
			isLoading,
			message,
			onContinue,
			onEmailChange,
			siteName,
			status,
		],
	);

	const onCreateUsernameContinue = useCallback(async () => {
		let resp;
		if (!rgisCookie) {
			return;
		}

		try {
			resp = await updateUsername(username || '', rgisCookie, siteId);
			if (resp.validation?.isValid) {
				setDisplayName(username ?? false);
				startTTH();
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}, [rgisCookie, siteId, startTTH, username]);
	const onUsernameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setUsername(e.target.value);
	}, []);

	if (showLogin) {
		return <LoginForm {...loginFormProps} />;
	}

	if (loggedIn && !displayName) {
		return (
			<CreateUsername
				errorMessage={usernameErrorMessage}
				onContinue={onCreateUsernameContinue}
				onUsernameChange={onUsernameChange}
			/>
		);
	}

	return children;
};
