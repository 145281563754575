import { type FC, Fragment } from 'react';
import type { AuthorTaxonomy } from '@/types/entities';
import { InternalLink } from '@/components/InternalLink';
import { decodeAndStripTags } from '@/utils/decode-and-strip-tags';
import styles from '@/components/raven/content/Byline/styles.module.css';

interface CreditsProps {
	credits: Map<string, AuthorTaxonomy[]>;
}

export const Credits: FC<CreditsProps> = ({ credits }) => {
	if (!credits) {
		return null;
	}

	const formattedCredits = Array.from(credits.entries())
		.map(([credit, authors]) => {
			// Format the authors
			const authorsList = authors
				.map((author) => {
					const safeAuthorName = decodeAndStripTags(author.name);

					return (
						<InternalLink
							aria-label={`Read more articles from ${safeAuthorName}`}
							className={styles.contributorCreditName}
							href={author.link}
							key={safeAuthorName}
						>
							{safeAuthorName}
						</InternalLink>
					);
				})
				.reduce<(JSX.Element | string)[]>(
					(acc, currentAuthor, index, authorsArray) => {
						// Add "and" before the last author if there are multiple authors
						if (index === authorsArray.length - 1 && authorsArray.length > 1) {
							return [...acc, ' and ', currentAuthor];
						}

						// If it's the last author, don't add a comma
						if (index === authorsArray.length - 1) {
							return [...acc, currentAuthor];
						}

						return [...acc, currentAuthor, ', '];
					},
					[],
				);

			return (
				<Fragment key={credit}>
					{`${credit} `} {authorsList}
				</Fragment>
			);
		})
		.reduce<(JSX.Element | string)[]>(
			(acc, currentCredit, index, creditsArray) => {
				// Add "and" before the last credit if there are multiple credits
				if (index === creditsArray.length - 1 && creditsArray.length > 1) {
					return [...acc, 'and ', currentCredit];
				}

				// If it's the last credit, don't add a comma
				if (index === creditsArray.length - 1) {
					return [...acc, currentCredit];
				}

				return [...acc, currentCredit, ', '];
			},
			[],
		);

	return <div className={styles.contributorsCredits}>{formattedCredits}</div>;
};
