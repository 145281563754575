import type { FC } from 'react';
import type { EmbedBlockProps } from '@/types/embeds';
import { EmbedWrapper } from '@/components/EmbedWrapper';
import { EmbedGDPR } from '@/components/raven/Blocks/embed/EmbedGDPR';
import { TikTokEmbedScript } from '@/components/raven/Blocks/embed/TikTokEmbedBlock/TikTokEmbedScript';

export const TikTokEmbedBlock: FC<EmbedBlockProps> = ({
	children,
	domNode: _, // Although we don't use this prop, we need to destructure it to avoid passing it to the GDPR and cause a Next.js error
	...rest
}) => (
	<EmbedGDPR
		purposes={['content']}
		vendorId="tiktok"
		{...rest}
		url={rest.block?.attributes.url}
	>
		<EmbedWrapper
			identifier="tiktok"
			url={(rest.block?.attributes.url || '') as string}
		>
			<TikTokEmbedScript />
			{children}
		</EmbedWrapper>
	</EmbedGDPR>
);
