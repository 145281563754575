import type { FC } from 'react';
import { Poll } from '@/components/Poll';
import type { PollData, PollDataOption } from '@/components/Poll/util';
import type { BlockProps, IDataWPBlock } from '@headstartwp/core/react';

export interface PollBlockAttributes extends IDataWPBlock {
	closedTime?: string;
	description?: string;
	id?: string;
	options?: { id: string; value: string }[];
	published: boolean;
	question?: string;
}

export const PollBlock: FC<BlockProps<PollBlockAttributes>> = ({ block }) => {
	if (!block?.attributes) {
		return null;
	}
	const {
		attributes: { closedTime, description, id, options, published, question },
	} = block;

	if (!id || !published) {
		return null;
	}

	const defaultData: PollData = {
		closed: false,
		closedTime,
		description: description || '',
		id,
		options:
			options?.map?.((e): PollDataOption => {
				return {
					id: e.id,
					isWinner: false,
					percentage: '0',
					score: 0,
					value: e.value,
				};
			}) || [],
		question: question || '',
		totalVotes: 0,
	};

	return <Poll id={id} pollData={defaultData} />;
};
