import { getAuthor } from '@/utils/get-author';
import type { AuthorEntity } from '@headstartwp/core';
import type { CustomPostEntity } from '@/types/blocks';
import type { ArticleTileProps } from '@/types/articles';

export interface ArticlesTilesOptions {
	aspectRatio: `${number}:${number}`;
	hideAuthor: boolean;
	hideCategory: boolean;
	hideDate: boolean;
	hideTags: boolean;
	postVariation: 'horizontal' | 'stacked';
}

export const generateArticleTiles = (
	posts: CustomPostEntity[],
	options: ArticlesTilesOptions,
) => {
	const {
		aspectRatio,
		hideAuthor,
		hideCategory,
		hideDate,
		hideTags,
		postVariation,
	} = options;

	return posts.map((post: CustomPostEntity) => ({
		aspectRatio,
		author_name: getAuthor(post.authors as AuthorEntity[]),
		category:
			post.categories.length > 0 && post.categories[0]?.name !== 'Uncategorized'
				? post.categories[0].name
				: '',
		date: post.date,
		hideAuthor,
		hideCategory,
		hideDate,
		hideTags,
		id: post.id,
		image: post.featured_image
			? {
					focalX: post.focal_point?.x,
					focalY: post.focal_point?.y,
					src: post.featured_image,
				}
			: null,
		link: post.link,
		tags:
			Array.isArray(post.tags) && post.tags.length > 0
				? post.tags.map((tag) => tag.name)
				: [],
		title: post.title,
		titleTag: 'h2',
		variation: postVariation,
		video: {
			thumbnail: post.listing_featured_video?.thumbnail_url,
			videoSrc: post.listing_featured_video?.videoSrc,
		},
	})) as ArticleTileProps[];
};
