'use client';

import { getAuth } from 'firebase/auth';
import type { Post } from '@/types/entities';
import { firebaseApp } from '@/utils/firebase';
import { Overlay } from '@/components/raven/Overlay';
import { useSiteContext } from '@/context/SiteContext';
import { useOverlayContext } from '@/context/OverlayContext';
import { type FC, useMemo, useEffect, useCallback } from 'react';
import { LoginProvider, useLoginContext } from '@/context/LoginContext';
import { CommentsLogin } from '@/components/raven/Comments/CommentsLogin';
import { Conversation } from '@/components/raven/Comments/OpenWeb/Conversation';
import {
	COMMENTS_VARIANT,
	useCommentsContext,
} from '@/context/CommentsContext';
import {
	OW_SDK_EVENT,
	type EventPayload,
	type OpenWebEvent,
} from '@/components/raven/Comments/OpenWeb';

interface CommentsDrawerProps {
	post: Post;
}

const CommentsDrawerBase: FC<CommentsDrawerProps> = ({ post }) => {
	const {
		commentsEnabled,
		setErrorMessage,
		setShowLogin,
		setStatus,
		showLogin,
		spotId,
	} = useCommentsContext();
	const { closeVariant, getOverlayRef, isOpen, openVariant } =
		useOverlayContext();
	const { rgisCookie } = useLoginContext();
	const loggedIn = Boolean(rgisCookie);
	const postId = post.meta.tempest_id || '';

	const addOWModals = useCallback(() => {
		if (!loggedIn) {
			return;
		}
		const overlayRef = getOverlayRef(COMMENTS_VARIANT, postId);
		if (overlayRef?.current?.querySelector('[open-web-ui-components-menus]')) {
			return;
		}
		const openWebMenus = document.querySelector(
			'[open-web-ui-components-menus]',
		);
		if (openWebMenus) {
			overlayRef?.current?.appendChild(openWebMenus);
		}
	}, [getOverlayRef, loggedIn, postId]);

	const handleOnClose = useCallback(
		(e: Event) => {
			if (showLogin) {
				setErrorMessage(null);
				setShowLogin(false);
				setStatus('idle');
				return;
			}
			window.history.replaceState(
				{},
				document.title,
				window.location.pathname + window.location.search,
			);
			closeVariant(COMMENTS_VARIANT, postId);
		},
		[closeVariant, postId, showLogin, setErrorMessage, setShowLogin, setStatus],
	);

	const conversationProps = useMemo(
		() => ({
			articleTags: post.tags.map((t) => t.name),
			authorId: post.authors?.[0]?.id.toString() || '',
			postId: post.meta.tempest_id || '',
			postUrl: post.link,
		}),
		[post.authors, post.link, post.meta.tempest_id, post.tags],
	);

	const handleOWEvent = useCallback(
		(e: OpenWebEvent) => {
			if (!loggedIn || !isOpen(COMMENTS_VARIANT, postId)) {
				return;
			}
			switch (e.detail.type) {
				case 'spot-im-user-click':
					addOWModals();
					if (
						['context-menu-item-mute', 'context-menu-item-report'].includes(
							(e.detail.payload as EventPayload).spotImClass,
						)
					) {
						closeVariant(COMMENTS_VARIANT, postId);
					}
					break;
				case 'spot-im-user-modal-closed':
					openVariant(COMMENTS_VARIANT, postId);
					break;
				case 'spot-im-user-modal-opened':
					closeVariant(COMMENTS_VARIANT, postId);
					break;
				default:
			}
		},
		[addOWModals, closeVariant, isOpen, loggedIn, openVariant, postId],
	);

	useEffect(() => {
		document.addEventListener(OW_SDK_EVENT, handleOWEvent);
		return () => {
			document.removeEventListener(OW_SDK_EVENT, handleOWEvent);
		};
	}, [handleOWEvent]);

	if (!spotId || !commentsEnabled(post)) {
		return null;
	}

	return (
		<Overlay
			className="right"
			id={postId}
			onClose={handleOnClose}
			variant={COMMENTS_VARIANT}
		>
			<CommentsLogin>
				<Conversation {...conversationProps} spotId={spotId} />
			</CommentsLogin>
		</Overlay>
	);
};

export const CommentsDrawer: FC<CommentsDrawerProps> = (props) => {
	const { firebase } = useSiteContext();
	const auth = useMemo(() => {
		return getAuth(firebaseApp(firebase.config));
	}, [firebase]);

	return (
		<LoginProvider auth={auth}>
			<CommentsDrawerBase {...props} />
		</LoginProvider>
	);
};
