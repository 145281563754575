import type { FC, ReactNode } from 'react';
import type { CardProps } from '@/types/card';
import { Card } from '@/components/raven/Card';
import { Image } from '@/components/raven/Image';
import type { SwipeGalleryImage } from '@/utils/image-utils';
import { ImageLinkWrapper } from '@/components/ImageLinkWrapper';
import { imageSizes } from '@/components/raven/Card/image-sizes';
import { ImageSource } from '@/components/raven/Blocks/ImageBlock/ImageSource';
import { CommerceInfoblock } from '@/components/raven/Commerce/CommerceInfoblock';
import {
	CarouselDefault,
	type CarouselProps,
} from '@/components/raven/content/Carousels/CarouselDefault';
import type { CommerceInfoblockPropsWithId } from '@/components/raven/Blocks/commerce/CommerceInfoblockBlock';

import styles from './styles.module.css';

type CarouselVariation =
	| 'article-carousel'
	| 'commerce-carousel'
	| 'gallery-carousel';

const GallerySlide: FC<{
	caption: ReactNode;
	image: SwipeGalleryImage;
	index: number;
}> = ({ caption, image, index }) => {
	return (
		<figure className={styles.mediaCarouselImageWrapper}>
			<ImageLinkWrapper
				link={image.link}
				linkClass={styles.mediaCarouselImageLink}
				linkTarget={image.linkTarget}
				linkTo={image.linkTo}
				rel={image.rel}
			>
				<Image
					alt={image?.alt_text ?? ''}
					className={styles.mediaCarouselImage}
					focalX={image?.meta?.focal_point?.x}
					focalY={image?.meta?.focal_point?.y}
					height={image.media_details.height}
					priority={index === 0}
					sizes={imageSizes.carousel}
					src={`${image && image.source_url ? image.source_url : ''}`}
					width={image.media_details.width}
				/>
			</ImageLinkWrapper>
			{caption && (
				<figcaption className={styles.mediaCarouselCaption}>
					{caption}
				</figcaption>
			)}

			<ImageSource
				className={styles.mediaCarouselImageSource}
				sourceName={image.img_src_name ?? ''}
				sourceUrl={image.img_src_url}
			/>
		</figure>
	);
};

function getSlidesMarkup(
	aspectRatio: string | undefined,
	caption: ReactNode | undefined,
	index: number,
	slide: CardProps | CommerceInfoblockPropsWithId | SwipeGalleryImage,
	variation: CarouselVariation,
) {
	switch (variation) {
		case 'article-carousel':
			return (
				<Card
					{...(slide as CardProps)}
					aspectRatio={aspectRatio}
					sizeOverride={imageSizes.carousel}
				/>
			);
		case 'commerce-carousel':
			return <CommerceInfoblock {...(slide as CommerceInfoblockPropsWithId)} />;
		case 'gallery-carousel':
			return (
				<GallerySlide
					caption={caption}
					image={slide as SwipeGalleryImage}
					index={index}
				/>
			);
		default:
			return undefined;
	}
}

export interface MediaCarouselProps extends CarouselProps {
	captions: Record<string, ReactNode>;
}

export const MediaCarousel: FC<MediaCarouselProps> = ({
	captions,
	ctaLink,
	slides,
	subtitle,
	title,
	variation,
}) => {
	return (
		slides &&
		slides.length > 0 && (
			<CarouselDefault
				ctaLink={ctaLink}
				slides={slides}
				subtitle={subtitle}
				title={title}
				variation={variation}
			>
				{slides.map((slide, index) => {
					let caption;
					if (variation === 'gallery-carousel') {
						caption = captions[(slide as SwipeGalleryImage)?.source_url];
					}
					let aspectRatio;
					if (variation === 'article-carousel') {
						aspectRatio = (slide as CardProps)?.aspectRatio;
					}

					return (
						<div className="carousel__slide" key={slide.id}>
							{getSlidesMarkup(
								aspectRatio,
								caption,
								index,
								slide,
								variation as CarouselVariation,
							)}
						</div>
					);
				})}
			</CarouselDefault>
		)
	);
};
