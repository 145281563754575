'use client';

import { type FC, useEffect } from 'react';
import type { Post } from '@/types/entities';
import { useAdService } from '@/context/AdServiceContext';

export const DemonetizationWatcher: FC<{ post: Post }> = ({ post }) => {
	const adService = useAdService();
	const monetization = post.meta?.monetization;

	useEffect(() => {
		const showAdsInPost = monetization !== 'demonetized';

		if (!showAdsInPost && adService.showAds) {
			adService.disableAds();
		}
	}, [monetization, adService]);

	return null;
};
