import clsx from 'clsx';
import type { FC } from 'react';
import { Cta } from '@/components/raven/Cta';
import { Image } from '@/components/raven/Image';
import type { CtaPropsWithIcon } from '@/types/cta';
import { HtmlDecoder } from '@headstartwp/core/react';
import {
	Breadcrumbs,
	type BreadcrumbItem,
} from '@/components/raven/content/Breadcrumbs';

import styles from './style.module.css';

export interface PageHeaderProps {
	backgroundImage?: {
		alt?: string;
		focalPoint?: { x: number; y: number };
		url?: string;
	};
	brandImage?: string;
	breadcrumbs?: BreadcrumbItem[];
	className?: string;
	cta?: CtaPropsWithIcon;
	description?: string;
	isPrimaryHeading?: boolean;
	isUppercase?: boolean;
	showBreadcrumbs?: boolean;
	sponsor?: {
		logo: string;
		name: string;
	};
	title: string;
}

export const PageHeader: FC<PageHeaderProps> = ({
	backgroundImage,
	brandImage,
	breadcrumbs,
	className,
	cta,
	description,
	isPrimaryHeading = false,
	isUppercase = false,
	showBreadcrumbs = true,
	sponsor,
	title,
}) => {
	const hasBreadcrumbs =
		showBreadcrumbs && breadcrumbs && breadcrumbs.length > 0;
	const hasBackgroundImage = backgroundImage && backgroundImage.url;
	const backgroundClassName = hasBackgroundImage
		? styles.pageHeaderWithBackground
		: '';
	const TagLevel = isPrimaryHeading ? 'h1' : 'h2';
	const titleText = isUppercase ? title.toUpperCase() : title;

	return (
		<section
			className={clsx(styles.pageHeader, className, backgroundClassName, {
				[styles.pageHeaderIsNotPrimaryHeading]: !isPrimaryHeading,
			})}
		>
			{hasBackgroundImage && (
				<>
					<figure className={styles.pageHeaderFigureBackground}>
						<Image
							alt={backgroundImage.alt || ''}
							aspectRatio="16:9"
							className={styles.pageHeaderBackground}
							fill
							focalX={backgroundImage.focalPoint?.x}
							focalY={backgroundImage.focalPoint?.y}
							priority
							sizes="(max-width: 1024px) 100vw, 1024px"
							src={backgroundImage.url || ''}
						/>
					</figure>
					<div className={styles.pageHeaderOverlay} />
				</>
			)}

			<div className={styles.pageHeaderContainer}>
				<div className={styles.pageHeaderWrapper}>
					<div className={styles.pageHeaderContent}>
						{hasBreadcrumbs && (
							<Breadcrumbs
								className={backgroundClassName}
								items={breadcrumbs}
								listItemLinkClassName={backgroundClassName}
							/>
						)}

						<div className={styles.pageHeaderContentHead}>
							{brandImage && (
								<Image
									alt=""
									className={styles.pageHeaderBrandImage}
									height={60}
									sizes="60px"
									src={brandImage}
									unoptimized
									width={60}
								/>
							)}
							<div className={styles.pageHeaderSponsorContent}>
								<TagLevel className={styles.pageHeaderTitle}>
									<HtmlDecoder html={titleText} />
								</TagLevel>

								{sponsor && sponsor.name && (
									<div className={styles.pageHeaderSponsorDetails}>
										<span className={styles.pageHeaderSponsorName}>
											{sponsor.name}
										</span>

										{sponsor.logo && (
											<Image
												alt={sponsor.name || ''}
												className={styles.pageHeaderSponsorLogo}
												height={150}
												src={sponsor.logo}
												unoptimized
												width={150}
											/>
										)}
									</div>
								)}
							</div>
						</div>

						{description && (
							<p className={styles.pageHeaderDescription}>
								<HtmlDecoder html={description} />
							</p>
						)}
					</div>

					{cta && (
						<div className={styles.pageHeaderCta}>
							<Cta cta={cta} icon={cta.icon} />
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
