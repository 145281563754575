import clsx from 'clsx';
import type { FC, HTMLAttributes, DetailedHTMLProps } from 'react';
import styles from '@/components/raven/ui/Container/styles.module.css';

interface ContainerProps
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	className?: string;
}

export const Container: FC<ContainerProps> = ({
	children,
	className,
	...rest
}) => {
	const cx = clsx(styles.container, className);

	return (
		<div className={cx} {...rest}>
			{children}
		</div>
	);
};
