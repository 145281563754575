/**
 * The `formatRecipeTime` function in TypeScript converts a given number of minutes into a duration
 * string in the format "PT{hours}H{minutes}M".
 * @param {number} [minutes] - The `formatRecipeTime` function takes a parameter `minutes` which is an
 * optional number representing the total duration in minutes that you want to format.
 * @returns The `formatRecipeTime` function returns a string representing a duration in ISO 8601 format.
 */
export const formatRecipeTime = (minutes?: number): string => {
	if (!minutes || minutes <= 0) {
		return '';
	}

	let result = 'PT';
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;

	if (hours > 0) {
		result += `${hours}H`;
	}

	if (remainingMinutes > 0) {
		result += `${remainingMinutes}M`;
	}

	return result;
};
